import React, { useContext, useEffect, useRef, useState } from "react";
import { Grid, CircularProgress, Backdrop } from "@mui/material";
import TotalSpent from "../../components/Dashboards/saas/TotalSpent";
import useTitle from "../../hooks/useTitle";
import DashboardCard from "../../components/Dashboards/saas/Card";
import { BackdropContext } from "../../contexts/BackdropContext";
import Footer from "../../components/Dashboards/saas/Footer";
import RecentOrders from "../../components/Dashboards/saas/RecentOrders";
import { notifyError, returnError } from "../../utils/common.service";
import { CONSTANT, PAGE_CONSTANT } from "../../utils/constant";
import CardBox from "../../components/Dashboards/saas/CardBox";
import TopSelling from "../../components/Dashboards/saas/TopSelling";

const SaaS = () => {
  useTitle("Dashboard");

  const [userData, setUserData] = useState([
    {
      customers: {
        activeCustomers: 77,
        deactiveCustomers: 67,
        suspendCustomers: 3,
        totalCustomers: 147,
      },

      staff: {
        activeStaff: 17,
        deactiveStaff: 38,
        suspendStaff: 1,
        totalStaff: 56,
      },
    },
  ]);
  const [postData, setPostData] = useState([
    {
      allPosts: 76,
      carryParcel: 24,
      publishedPost: 72,
      travelPartner: 52,
      unpublishedPost: 4,
    },
  ]);
  const [ticketData, setTicketData] = useState([
    { activeTicket: 4, allTickets: 33, closedTicket: 3, newTicket: 26 },
  ]);
  const [transactionData, setTransactionData] = useState([]);
  const [orderData, setOrderData] = useState([
    { allOrders: 10, bumpOrders: 4, refundOrders: 4, successOrders: 6 },
  ]);

  const { backdropOpen, setBackdropOpen } = useContext(BackdropContext);
  const [transactionAmount, setTransactionAmount] = useState([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(CONSTANT.SELECTED_MENU[0]);
  const anchorRef = useRef(null);

  useEffect(() => {
    document.title = PAGE_CONSTANT.DASHBOARD;
  }, []);

  const handleChange = (item) => {
    setSelected(item);
    setOpen(false);
  };

  return (
    <Grid container spacing={4} pt={4}>
      {backdropOpen && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {backdropOpen && userData.length <= 0 && postData.length <= 0 ? (
        <Grid item xs={12}>
          <CardBox />
        </Grid>
      ) : (
        <Grid item xs={12}>
          {userData.map((user, index) => (
            <React.Fragment key={index}>
              <DashboardCard
                userData={user}
                postData={postData}
                ticketData={ticketData}
              />
            </React.Fragment>
          ))}
        </Grid>
      )}

      <Grid item lg={12} md={12} xs={12}>
        <TotalSpent
          handleChange={handleChange}
          selected={selected}
          transactionAmount={transactionAmount}
          open={open}
          setOpen={setOpen}
          anchorRef={anchorRef}
        />
      </Grid>

      <Grid item lg={8} md={7} xs={12}>
        <RecentOrders />
      </Grid>
      <Grid item lg={4} md={5} xs={12}>
        <TopSelling />
      </Grid>
      <Grid item xs={12}>
        <Footer imageLink="/static/illustration/sass-dashboard.svg" />
      </Grid>
    </Grid>
  );
};

export default SaaS;
