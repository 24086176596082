import React, { useEffect, useState, useContext } from "react";
import {
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import FlexBox from "../../components/FlexBox";
import SearchInput from "../../components/SearchInput";
import SupportListTable from "../../components/userManagement/CustomTable";
import useTitle from "../../hooks/useTitle";
import { useNavigate } from "react-router-dom";
import "../../CommonCss/Button.css";
import { Small } from "../../components/Typography";
import userService from "../../service/user.service";
import {
  StyledPagination,
  capitalizeFirstLetter,
  notifyError,
  notifySuccess,
} from "../../utils/common.service";
import { Stack } from "@mui/system";
import { CONSTANT, PAGE_CONSTANT } from "../../utils/constant";
import "./Support.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import NodataFound from "../../components/Layouts/NodataFound";
import FilterMenu from "../../components/card/filterMenu";
import { BackdropContext } from "../../contexts/BackdropContext";

const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const Support = () => {
  useTitle("Support");

  useEffect(() => {
    document.title = PAGE_CONSTANT.SUPPORT;
  }, []);

  const navigate = useNavigate();
  const { backdropOpen, setBackdropOpen } = useContext(BackdropContext);
  const [supportList, setSupportList] = useState([]);
  const [totalRecord, setTotalRecord] = useState("");
  const [pageObj, setPageObj] = useState({
    pageCount: 1,
    limit: 5,
    search: "",
    sortingField: "",
    sortingOrder: "desc",
  });
  const [search, setSearch] = useState("");
  const [windowHeight, setWindowHeight] = useState(800);

  useEffect(() => {
    const height = window.innerHeight;
    setWindowHeight(height);

    handleSupportList({ ...pageObj });
  }, [pageObj]);

  useEffect(() => {}, [window?.innerWidth]);
  const UserListColumnShape = [
    {
      Header: "User Id",
      accessor: "_id",
      minWidth: 150,
      Cell: ({ value }) => {
        return (
          <FlexBox alignItems="center">
            <FlexBox flexDirection="column">
              <Small color="text.primary">{value || "-"}</Small>
            </FlexBox>
          </FlexBox>
        );
      },
    },

    {
      Header: "TicketNo",
      accessor: "ticketNo",
      minWidth: 150,
      Cell: ({ value }) => {
        return (
          <FlexBox alignItems="center">
            <FlexBox flexDirection="column">
              <Small color="text.primary">{value || "-"}</Small>
            </FlexBox>
          </FlexBox>
        );
      },
    },
    {
      Header: "Title",
      accessor: "title",
      minWidth: 270,
      Cell: ({ row }) => {
        const { title } = row.original;
        return (
          <Small
            sx={{
              color: "text.primary",
            }}
            className="title-text-ellipsis"
          >
            {title || "-"}
          </Small>
        );
      },
    },

    {
      Header: "Created Date",
      accessor: "createdAt",
      minWidth: 140,
      Cell: ({ value }) => {
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {moment(value).format(CONSTANT.DATE_FORMATE)}
            </Small>{" "}
            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {moment(value).format("LT")}
            </Grid>
          </>
        );
      },
    },

    {
      Header: "Updated Date",
      accessor: "updatedAt",
      minWidth: 100,
      Cell: ({ value }) => {
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {moment(value).format(CONSTANT.DATE_FORMATE)}
            </Small>

            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {moment(value).format("LT")}
            </Grid>
          </>
        );
      },
    },

    {
      Header: "Status",
      accessor: "status",
      minWidth: 140,
      Cell: ({ value }) => {
        return (
          <FormControl>
            <Chip
              label={value.charAt(0).toUpperCase() + value.slice(1)}
              sx={{
                bgcolor:
                  value === "active"
                    ? "orange"
                    : value === "new"
                    ? "#ff4747"
                    : "green",
                color: "white",
                minWidth: "100px",
              }}
            />
          </FormControl>
        );
      },
    },

    {
      Header: "Action",
      accessor: "action",
      minWidth: 80,
      maxWidth: 50,
      Cell: ({ row }) => {
        return (
          <FlexBox sx={{ display: "flex", justifyContent: "right" }}>
            <Tooltip
              title="Support"
              placement="top-start"
              sx={{
                cursor: "pointer",
                border: "1px solid",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
                padding: "2px",
                mr: "10px",
              }}
              onClick={() => {
                handleOpenThread(row);
              }}
            >
              <RemoveRedEyeIcon className="icons-details" color="black" />
            </Tooltip>
          </FlexBox>
        );
      },
    },
  ];

  const handleSupportList = async (obj) => {
    try {
      setBackdropOpen(true);
      let data = { ...obj, pageCount: obj.pageCount - 1 };

      userService
        .getSupports({ ...data })
        .then((res) => {
          const { message } = res;
          if (res.status === 200) {
            setBackdropOpen(false);
            setSupportList(res?.data || []);
            setTotalRecord(res?.totalRecords || 0);
            notifySuccess(res.message);
          } else {
            setTotalRecord(res?.totalRecords || 0);
            setSupportList([]);
            setBackdropOpen(false);
            notifyError(res.message);
          }
        })
        .catch((err) => {
          setBackdropOpen(false);
          notifyError(err ? err?.response?.data?.message : err?.message);
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(err ? err?.response?.data?.message : err?.message);
    }
  };

  const handleChange = (_event, currentPage) => {
    setPageObj({ ...pageObj, pageCount: currentPage });
  };

  const handleRowsChange = (event, currentPage) => {
    setPageObj({
      ...pageObj,
      pageCount: 1,
      limit: parseInt(event.target.value),
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(event.target.value);
    }
  };

  const handleSearch = (value) => {
    setPageObj({ ...pageObj, search: value, pageCount: 1 });
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      pageObj["sortingField"] === property && pageObj["sortingOrder"] === "asc";
    const order = isAsc ? "desc" : "asc";
    setPageObj({
      ...pageObj,
      sortingField: property,
      sortingOrder: order,
    });
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleOpenThread = (row) => {
    if (row) {
      navigate("/dashboard/support-thread", { state: row?.original });
    }
  };

  const handleResetFilter = () => {
    setPageObj({
      ...pageObj,
      search: "",
      sortingField: "",
      sortingOrder: "desc",
    });
    setSearch("");
  };

  return (
    <>
      <Box pt={2}>
        <StyledFlexBox sx={{ pb: "20px" }} className="style-box">
          <SearchInput
            placeholder="Search support..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <FilterMenu
            setPageObj={setPageObj}
            pageObj={pageObj}
            showReset={true}
            showSort={false}
            showStatus={false}
            showRangePicker={false}
            showExport={false}
            handleResetFilter={handleResetFilter}
          />
        </StyledFlexBox>

        <Grid>
          <SupportListTable
            isSort={true}
            isRowClick={true}
            pageObj={pageObj}
            columnShape={UserListColumnShape}
            data={supportList}
            hidePagination={false}
            createSortHandler={createSortHandler}
            handleChange={handleChange}
            handleRowsChange={handleRowsChange}
            totalRecord={totalRecord}
            users={"Total Record:"}
            setHeight={"calc(70vh - 10px)"}
            setHighHeight={"calc(77vh - 10px)"}
            windowHeight={windowHeight}
          />
          {!backdropOpen &&
            (!supportList || (supportList.length <= 0 && <NodataFound />))}
        </Grid>

        {backdropOpen && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              Total Record:
            </Typography>

            <Small
              sx={{
                borderRadius: 10,
                padding: "6px 30px 6px 30px",
                color: "black",
                backgroundColor: "rgb(255, 225, 53)",
              }}
            >
              {totalRecord || 0}
            </Small>
          </Grid>

          <Stack>
            <StyledPagination
              count={
                (totalRecord && Math.ceil(totalRecord / pageObj.limit)) || 0
              }
              shape="rounded"
              page={pageObj.pageCount}
              onChange={handleChange}
              onRowsPerPageChange={handleRowsChange}
            />
          </Stack>
        </Grid>
      </Box>
    </>
  );
};

export default Support;
