import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import LightTextField from "../../components/LightTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import "../../CommonCss/Button.css";
import { MESSAGE } from "../../utils/constant.jsx";
import userService from "../../service/user.service";
import {
  deleteStatus,
  isArrayEmpty,
  notifyError,
  notifySuccess,
  returnError,
} from "../../utils/common.service";
import { BootstrapDialogTitle } from "../../components/Layouts/Modal/CustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import "../../CommonCss/Button.css";

const IngredientForm = (props) => {
  const { editData, closeModal, setPageObj, pageObj, id } = props;

  const [buttonLoading, setButtonLoading] = useState(false);

  const initialValues = {
    _id: id,
    title: editData?.data?.title,
    health_status: editData?.data?.health_status,
    description: editData?.data?.description,
    detailedOverview: editData?.data?.detailedOverview,
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(MESSAGE.TITLE),
  });

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async () => {
        try {
          setButtonLoading(true);

          userService
            .updateIngredientById(id, {
              title: values.title,
              health_status: values.health_status,
              description: values.description,
              detailedOverview: values.detailedOverview,
            })
            .then((res) => {
              if (res.status === 200) {
                setButtonLoading(false);
                notifySuccess(res?.message);
                setPageObj({
                  ...pageObj,
                  refresh: Date.now(),
                });

                closeModal();
              } else {
                setButtonLoading(false);
                notifySuccess(res?.message);
              }
            })
            .catch((err) => {
              setButtonLoading(false);
              notifyError(err ? err?.response?.data?.message : err?.message);
            });
        } catch (err) {
          setButtonLoading(false);
          notifyError(err ? err?.response?.data?.message : err?.message);
        }
      },
    });

  return (
    <form onSubmit={handleSubmit} enctype="multipart/form-data">
      <BootstrapDialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-20px",
          marginLeft: "-8px",
          marginBottom: "-10px",
        }}
      >
        <h3>Update Ingredient</h3>

        <IconButton>
          <CloseIcon onClick={closeModal} />
        </IconButton>
      </BootstrapDialogTitle>

      <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 172px)" }}>
        <Grid
          rowSpacing={{ xs: 3 }}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          container
          sx={{
            height: "350px",
            overflow: "auto",
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Title
            </Typography>
            <LightTextField
              fullWidth
              name="title"
              type="text"
              placeholder="Title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Health Status
            </Typography>
            <Grid item xs={12} md={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel>Health Status</InputLabel>
                <Select
                  label="Health Status"
                  name="health_status"
                  value={values.health_status}
                  onChange={handleChange}
                  error={Boolean(touched.health_status && errors.health_status)}
                >
                  <MenuItem value="unhealthy">Unhealthy</MenuItem>
                  <MenuItem value="healthy">Healthy</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Description
            </Typography>
            <LightTextField
              fullWidth
              name="description"
              type="text"
              multiline
              rows={4}
              placeholder="Description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Detailed Overview
            </Typography>
            <LightTextField
              fullWidth
              name="detailedOverview"
              type="text"
              multiline
              rows={4}
              placeholder="Detailed Overview"
              value={values.detailedOverview}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              sx={{ maxWidth: "200px" }}
              type="submit"
              loading={buttonLoading}
              loadingPosition="start"
              variant="contained"
              fullWidth
              className="button"
            >
              Update Ingredient
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default IngredientForm;
