import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  styled,
  useMediaQuery,
} from "@mui/material";
import ScrollBar from "simplebar-react";
import logo from "../../Image/logo/Ingredients_Checker.png";

import SubMenu from "./DashboardSub.jsx";
import Icons from "../../icons/sidebar";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import userService from "../../service/user.service";
import { notifyError, returnError } from "../../utils/common.service";

const MainMenu = styled(Box)(({ theme }) => ({
  left: 0,
  width: 230,
  height: "100%",
  position: "fixed",
  boxShadow: theme.shadows[2],
  transition: "left 0.5s ease",
  zIndex: theme.zIndex.drawer + 11,
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down("md")]: { left: -80 },
  "& .simplebar-track.simplebar-vertical": { width: 7 },
  "& .simplebar-scrollbar:before": {
    background: theme.palette.text.primary,
  },
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  marginBottom: "1rem",
  justifyContent: "center",
  "&:hover": { backgroundColor: "transparent" },
}));

const DashboardSideBar = ({ showMobileSideBar, closeMobileSideBar }) => {
  const navigation = useNavigate();

  const [sideBarData, setSideBarData] = useState(["Dashboard"]);

  const data = [
    "Dashboard",
    "Users",
    "Ingredients",
    "Support",
    "Activity",
    "History",
    "Feedback",
  ];

  useEffect(() => {
    if (localStorage.getItem(btoa("token"))) {
      let token = localStorage.getItem(btoa("token"));
      const decode = jwtDecode(token);
      if (decode?.role === "admin") {
        setRoleAccess(data);
      } else {
        setRoleAccess(["Dashboard"]);
      }
    }
  }, []);

  const setRoleAccess = () => {
    const SidebarData = [
      {
        title: "Dashboard",
        Icon: Icons.DashboardIcon,
        path: "/dashboard",
        hidden: !data.includes("Dashboard"),
      },

      {
        title: "Users",
        Icon: Icons.ManageAccountsOutlined,
        path: "/dashboard/users",
        hidden: !data.includes("Users"),
      },

      {
        title: "Ingredients",
        Icon: Icons.FoodBankOutlined,
        path: "/dashboard/ingredients",
        hidden: !data.includes("Ingredients"),
      },
      {
        title: "Support",
        Icon: Icons.SupportAgent,
        path: "/dashboard/support",
        hidden: !data.includes("Support"),
      },
      {
        title: "Activity",
        Icon: Icons.AssessmentOutlinedIcon,
        path: "/dashboard/activity",
        hidden: !data.includes("Activity"),
      },

      {
        title: "History",
        Icon: Icons.HistoryOutlined,
        path: "/dashboard/history",
        hidden: !data.includes("History"),
      },
      {
        title: "Feedback",
        Icon: Icons.RateReviewOutlined,
        path: "/dashboard/feedback",
        hidden: !data.includes("Feedback"),
      },
    ];

    const filteredSidebarData = SidebarData.filter((item) => !item.hidden);
    setSideBarData(filteredSidebarData);
  };

  const downMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const mainSideBarContent = (
    <List sx={{ height: "100%" }} onClick={closeMobileSideBar}>
      <StyledListItemButton disableRipple>
        <img src={logo} alt="IO" width={130} />
      </StyledListItemButton>
      <ScrollBar style={{ maxHeight: "calc(100% - 50px)" }}>
        {sideBarData.map((nav, index) => {
          return (
            <React.Fragment key={index}>
              <SubMenu nav={nav} key={index} />
            </React.Fragment>
          );
        })}
      </ScrollBar>
    </List>
  );

  if (downMd) {
    return (
      <Drawer
        anchor="left"
        open={showMobileSideBar}
        onClose={closeMobileSideBar}
        PaperProps={{ sx: { width: "230px" } }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            width: "230px",
            position: "fixed",
            flexDirection: "column",
            boxShadow: (theme) => theme.shadows[1],
            backgroundColor: (theme) => theme.palette.background.paper,
            "& .simplebar-track.simplebar-vertical": { width: 7 },
            "& .simplebar-scrollbar:before": {
              background: (theme) => theme.palette.text.primary,
            },
          }}
        >
          {mainSideBarContent}
        </Box>
      </Drawer>
    );
  }

  return <MainMenu>{mainSideBarContent}</MainMenu>;
};

export default DashboardSideBar;
