import React from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import "../../CommonCss/Button.css";

import ScrollBar from "simplebar-react";
import { isArrayEmpty } from "../../utils/common.service";
import { BootstrapDialogTitle } from "../../components/Layouts/Modal/CustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import { H3, Small } from "../../components/Typography";

function HistoryPage(props) {
  const {
    closeModal,
    editData,
    pageObj,
    isSort = false,
    createSortHandler,
  } = props;

  return (
    <Grid id="history">
      <BootstrapDialogTitle
        sx={{
          position: "sticky",
          top: "-10px",
          zIndex: "999",
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-20px",
          marginLeft: "-8px",
          marginBottom: "-10px",
        }}
      >
        <h3>History</h3>

        <IconButton>
          <CloseIcon onClick={closeModal} />
        </IconButton>
      </BootstrapDialogTitle>

      <ScrollBar sx={{ overflowY: "auto", maxHeight: "calc(100vh - 172px)" }}>
        <Table
          sx={{
            borderSpacing: "0 1rem",
            borderCollapse: "separate",
          }}
        >
          {editData?.userRecords?.activity ? (
            <>
              <TableHead className="sticky-table-header">
                <TableRow>
                  <TableCell
                    sx={{
                      paddingY: 0,
                      fontSize: 13,
                      fontWeight: 600,
                      width: "150px",
                      borderBottom: 0,
                      textAlign: "left",
                      color: "text.primary",

                      "& .MuiTableSortLabel-icon": {
                        color: "black !important",
                      },
                      "&:last-child": { textAlign: "left" },
                      "&:hover": { color: "black" },
                      "&:focus": { color: "black" },
                    }}
                    direction={pageObj["sortingOrder"]}
                  >
                    Product Image
                  </TableCell>
                  <TableCell
                    sx={{
                      paddingY: 0,
                      fontSize: 13,
                      fontWeight: 600,
                      width: "100px",
                      borderBottom: 0,
                      textAlign: "left",
                      color: "text.primary",

                      "& .MuiTableSortLabel-icon": {
                        color: "black !important",
                      },
                      "&:last-child": { textAlign: "left" },
                      "&:hover": { color: "black" },
                      "&:focus": { color: "black" },
                    }}
                    direction={pageObj["sortingOrder"]}
                  >
                    Search Id
                  </TableCell>
                  <TableCell
                    sx={{
                      paddingY: 0,
                      fontSize: 13,
                      width: "190px",
                      fontWeight: 600,
                      borderBottom: 0,
                      textAlign: "left",
                      color: "text.primary",

                      "& .MuiTableSortLabel-icon": {
                        color: "black !important",
                      },
                      "&:last-child": { textAlign: "left" },
                      "&:hover": { color: "black" },
                      "&:focus": { color: "black" },
                    }}
                    direction={pageObj["sortingOrder"]}
                  >
                    Category
                  </TableCell>
                  <TableCell
                    sx={{
                      paddingY: 0,
                      fontSize: 13,
                      width: "230px",
                      fontWeight: 600,
                      borderBottom: 0,
                      textAlign: "left",
                      color: "text.primary",

                      "& .MuiTableSortLabel-icon": {
                        color: "black !important",
                      },
                      "&:last-child": { textAlign: "left" },
                      "&:hover": { color: "black" },
                      "&:focus": { color: "black" },
                    }}
                    direction={pageObj["sortingOrder"]}
                  >
                    Product Name
                  </TableCell>
                  <TableCell
                    sx={{
                      paddingY: 0,
                      fontSize: 13,
                      fontWeight: 600,
                      borderBottom: 0,
                      color: "text.primary",

                      "& .MuiTableSortLabel-icon": {
                        color: "black !important",
                      },
                      "&:last-child": { textAlign: "left" },
                      "&:hover": { color: "black" },
                      "&:focus": { color: "black" },
                    }}
                    direction={pageObj["sortingOrder"]}
                  >
                    Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {editData?.userRecords?.activity?.map((row, i) => {
                  return (
                    <TableRow
                      key={"row_" + i}
                      sx={{
                        backgroundColor: "background.paper",
                        // cursor: isRowClick ? "pointer" : "unset",
                        "& td:first-of-type": {
                          borderLeft: "1px solid",
                          borderTopLeftRadius: "8px",
                          borderBottomLeftRadius: "8px",
                        },
                        "& td:last-of-type": {
                          textAlign: "justify",
                          borderRight: "1px solid",
                          borderTopRightRadius: "8px",
                          borderBottomRightRadius: "8px",
                        },
                      }}
                    >
                      <TableCell
                        sx={{
                          width: "50px",
                          fontSize: 13,
                          fontWeight: 500,
                          color: "#1d2438",
                          borderTop: "1px solid",
                          borderBottom: "1px solid",
                        }}
                      >
                        {row?.imageUrl !== "" ? (
                          <Tooltip title="Open Image" placement="top" arrow>
                            <a
                              href={row?.imageUrl}
                              target="_black"
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={row?.imageUrl}
                                className="historyImage"
                                alt="Sanket"
                                onLoad={() => console.log("first ---", i)}
                              />
                            </a>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "50px",
                          fontSize: 13,
                          fontWeight: 500,
                          color: "#1d2438",
                          borderTop: "1px solid",
                          borderBottom: "1px solid",
                        }}
                      >
                        <Tooltip title={row?._id} placement="top-start">
                          <p className="post-text-ellipsis">
                            {row?._id || "-"}
                          </p>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: "#1d2438",
                          borderTop: "1px solid",
                          borderBottom: "1px solid",
                        }}
                      >
                        {row?.answer[row?.answer?.length - 1]?.category}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: "#1d2438",
                          borderTop: "1px solid",
                          borderBottom: "1px solid",
                        }}
                      >
                        {row?.answer[row?.answer?.length - 1]?.product_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: "#1d2438",
                          borderTop: "1px solid",
                          borderBottom: "1px solid",
                        }}
                      >
                        {
                          row?.answer[row?.answer?.length - 1]
                            ?.product_description
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </>
          ) : (
            <H3 sx={{ textAlign: "center", color: "red" }}>
              This user has not any history!
            </H3>
          )}
        </Table>
      </ScrollBar>
    </Grid>
  );
}

export default HistoryPage;
