import React, { useEffect, useState } from "react";
import { Card, Typography } from "@mui/material";
import FlexBox from "../../components/FlexBox";
import LightTextField from "../../components/LightTextField";
import { H1 } from "../../components/Typography";
import { TextFieldWrapper } from "../../components/authentication/StyledComponents";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import UserService from "../../service/user.service";
import { LoadingButton } from "@mui/lab";
import "../../CommonCss/Button.css";
import { MESSAGE, PAGE_CONSTANT } from "../../utils/constant.jsx";
import {
  deleteStatus,
  notifyError,
  notifySuccess,
} from "../../utils/common.service";

const UserVerification = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const emailData = localStorage.getItem(btoa("email"));
  const email = atob(emailData);

  useEffect(() => {
    document.title = PAGE_CONSTANT.INGREDIENTS_CHECKER;
  }, []);

  const initialValues = {
    otp: "",
    terms: true,
    submit: null,
  };

  const validationSchema = Yup.object().shape({
    otp: Yup.string().required(MESSAGE.OTP).min(4, MESSAGE.OTP_MIN),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (actions) => {
        try {
          setLoading(true);
          UserService.verificationUser({
            otp: values?.otp,
          })
            .then((res) => {
              const { status, message } = res;

              if (status === 200) {
                setLoading(false);
                notifySuccess(deleteStatus(res));
                navigate("/login");
                actions.resetForm();
              } else {
                setLoading(false);
                notifyError(deleteStatus(res) || MESSAGE.NETWORK_ERROR);
              }
            })
            .catch((err) => {
              setLoading(false);
            });
        } catch (err) {
          setLoading(false);
          notifyError(err ? err?.response?.data?.message : err?.message);
        }
      },
    });

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: { sm: "100%" },
      }}
    >
      <Card sx={{ padding: 4, maxWidth: 600, boxShadow: 1 }}>
        <FlexBox
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mb={5}
        >
          <H1 fontSize={24} fontWeight={700}>
            User Verification
          </H1>
        </FlexBox>

        <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
          <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Typography>
              <strong>{email.replace(/"/g, " ")}</strong>
            </Typography>

            <TextFieldWrapper sx={{ mt: 2, width: "100%" }}>
              <LightTextField
                fullWidth
                autoComplete="off"
                name="otp"
                type="text"
                label="Otp"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.otp || ""}
                error={Boolean(touched.otp && errors.otp)}
                helperText={touched.otp && errors.otp}
              />
            </TextFieldWrapper>

            <FlexBox sx={{ mt: 2 }}>
              <LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                variant="contained"
                fullWidth
                className="button"
              >
                Verify
              </LoadingButton>
            </FlexBox>
          </form>
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default UserVerification;
