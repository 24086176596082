import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Card, FormControlLabel, Switch } from "@mui/material";
import FlexBox from "../../components/FlexBox";
import LightTextField from "../../components/LightTextField";
import { H1, Paragraph, Small } from "../../components/Typography";
import { TextFieldWrapper } from "../../components/authentication/StyledComponents";
import { useFormik } from "formik";
import useAuth from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import logo from "../../Image/logo/Ingredients_Checker.png";
import "../../CommonCss/Button.css";
import { MESSAGE, PAGE_CONSTANT } from "../../utils/constant.jsx";
import { userTokenMethod } from "../../utils/common.service";

const Login = () => {
  const { login, isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = PAGE_CONSTANT.INGREDIENTS_CHECKER;
  }, []);

  const initialValues = {
    email: "",
    password: "",
    submit: null,
    remember: true,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(MESSAGE.EMAIL_VALID)
      .max(255)
      .required(MESSAGE.EMAIL_REQUIRED),
    password: Yup.string()
      .min(6, MESSAGE.PASSWORD_MINIMUM)
      .required(MESSAGE.PASSWORD_REQUIRED),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        try {
          setLoading(true);
          let response = await login(values.email, values.password);

          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      },
    });

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.removeItem(btoa("email"));
      navigate("/dashboard");
    }
  }, [isAuthenticated]);

  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: { sm: "100%" },
      }}
    >
      <Card sx={{ padding: 4, maxWidth: 600, boxShadow: 1 }}>
        <FlexBox
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mb={5}
        >
          <Box width={100} mb={1}>
            <img src={logo} width="100%" alt="Ingredients_Checker Logo" />
          </Box>
          <H1 fontSize={24} fontWeight={700}>
            Login to Ingredients Checker
          </H1>
        </FlexBox>

        <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
          <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
            <FlexBox justifyContent="space-between" flexWrap="wrap">
              <TextFieldWrapper sx={{ mt: 2, width: "100%" }}>
                <Paragraph fontWeight={600} mb={1}>
                  Email
                </Paragraph>
                <LightTextField
                  fullWidth
                  name="email"
                  type="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email || ""}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </TextFieldWrapper>

              <TextFieldWrapper sx={{ mt: 2, width: "100%" }}>
                <Paragraph fontWeight={600} mb={1}>
                  Password
                </Paragraph>
                <LightTextField
                  fullWidth
                  name="password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password || ""}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </TextFieldWrapper>
            </FlexBox>

            <FlexBox mt={2} alignItems="center" justifyContent="space-between">
              <Link to="/forget-password">
                <Small color="secondary.red">Forgot Password?</Small>
              </Link>
            </FlexBox>

            <Box sx={{ mt: 4 }}>
              <LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                variant="contained"
                fullWidth
                className="button"
              >
                Log In
              </LoadingButton>
            </Box>
          </form>

          <Small margin="auto" mt={3} color="text.disabled">
            Don't have an account?{" "}
            <Link to="/register">
              <Small color="primary.main">Create an account</Small>
            </Link>
          </Small>
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default Login;
