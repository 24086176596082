import React, { useState, useRef, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Tooltip,
  Avatar,
  Backdrop,
  CircularProgress,
  Divider,
  Select,
  FormControl,
  MenuItem,
  Chip,
  TextareaAutosize,
} from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  capitalizeFirstLetter,
  notifyError,
  notifySuccess,
  stringAvatar,
} from "../../utils/common.service";
import { H3, H4, Small } from "../../components/Typography";
import userService from "../../service/user.service";
import { CONSTANT, PAGE_CONSTANT } from "../../utils/constant";
import { useFormik } from "formik";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import useTitle from "../../hooks/useTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputEmoji from "react-input-emoji";
import "./Support.css";
import { BackdropContext } from "../../contexts/BackdropContext";

const SupportThread = (e) => {
  useTitle(PAGE_CONSTANT.SUPPORT_THREAD);
  const navigation = useNavigate();

  const { state } = useLocation();

  const [statusChange, setStatusChange] = useState([]);
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [messageData, setMessageData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const inputFile = useRef(null);
  const chatContainerRef = useRef(null);
  const { backdropOpen, setBackdropOpen } = useContext(BackdropContext);
  const handleEmojiInputChange = (newText) => {
    handleChange({
      target: {
        name: "message",
        value: newText,
      },
    });
  };

  const initialValues = {
    message: "",
    submit: null,
  };

  useEffect(() => {
    document.title = PAGE_CONSTANT.SUPPORT_THREAD;
  }, []);

  useEffect(() => {
    getThreadMessageByTicket();
  }, [state.ticketNo]);

  const handleAttachment = (e) => {
    setFile([...file, e.target.files[0]]);
    const fileData = e.target.files[0];
    setFileName(fileData);
  };

  const getThreadMessageByTicket = () => {
    try {
      setBackdropOpen(true);
      userService
        .getThreadByTicket(state.ticketNo)
        .then((res) => {
          const { data, message } = res;
          if (res.status === 200) {
            setBackdropOpen(false);
            setMessageData(data);
          } else {
            setBackdropOpen(false);
            notifyError(message);
          }
        })
        .catch((err) => {
          setBackdropOpen(false);
          notifyError(err ? err?.response?.data?.message : err?.message);
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(err ? err?.response?.data?.message : err?.message);
    }
  };

  const handleSetStatus = async (status, _id) => {
    const responseData = await userService.updateSupportStatus(_id, status);
    if (responseData && responseData.status === 200) {
      notifySuccess(responseData.message);
      setStatusChange(responseData);
    } else {
      notifyError(responseData.message);
    }
  };
  const { values, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit: (values, actions) => {
      try {
        setButtonLoading(true);

        if (!values.message && (!file || file.length <= 0)) {
          notifyError("Require field are missing");
        } else {
          const formData = new FormData();
          formData.append("user_id", state.user);
          formData.append("attachment", file[0]);
          formData.append("message", values.message);
          formData.append("ticketNo", state.ticketNo);

          userService
            .createThread(formData)
            .then((res) => {
              const { status, message } = res;
              if (status === 200) {
                setButtonLoading(false);
                getThreadMessageByTicket();
                setFile("");
                setFileName("");
                actions.resetForm();
                chatContainerRef.current.scrollTop =
                  chatContainerRef.current.scrollHeight;
              } else {
                setButtonLoading(false);
                notifyError(message);
              }
            })
            .catch((err) => {
              setButtonLoading(false);
            });
        }
      } catch (err) {
        setButtonLoading(false);
        notifyError(err ? err?.response?.data?.message : err?.message);
      }
    },
  });

  return (
    <>
      <Card sx={{ minWidth: 275, p: 2 }}>
        <Grid container>
          <Grid
            sx={{
              display: "flex",
              alignItems: "left",
            }}
          >
            <H4 onClick={() => navigation("/dashboard/support")}>
              <Button
                variant="text"
                sx={{
                  color: "black",
                }}
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            </H4>
          </Grid>
        </Grid>
        <CardContent>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={8} sx={{ mb: 2 }}>
              <H3 sx={{ textAlign: "left" }}>{state?.title}</H3>

              <Typography sx={{ pt: 3, textAlign: "left" }}>
                {state?.description}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Grid
                sx={{ display: "flex", justifyContent: "right", mr: 1.4 }}
                className="thread-ticket"
              >
                <Typography sx={{ fontWeight: 600 }}>Ticket No :</Typography>
                <Typography sx={{ color: "gray", ml: 1 }}>
                  {state?.ticketNo}
                </Typography>
              </Grid>

              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  mt: 3,
                }}
                className="thread-status"
              >
                <Typography sx={{ fontWeight: 600, mr: 1.5 }}>
                  Status
                </Typography>
                <FormControl sx={{ cursor: "pointer" }}>
                  <Select
                    inputProps={{ IconComponent: () => null }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={
                      statusChange.length === 0
                        ? state?.status
                        : statusChange?.post?.status
                    }
                    label="Status"
                    onChange={(e) => {
                      e.stopPropagation();
                      handleSetStatus(e.target.value, state?._id);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                        padding: "0px !important",
                        background: "transparent",
                      },
                      ".MuiSelect-select": {
                        padding: 0,
                      },
                      cursor: "pointer",
                    }}
                  >
                    <MenuItem value="active">
                      <Chip
                        label="Active"
                        sx={{
                          cursor: "pointer",
                          minWidth: "100px",
                          textOverflow: "unset",
                          overflow: "none",
                          bgcolor: "orange",
                        }}
                      />
                    </MenuItem>
                    <MenuItem value="new">
                      <Chip
                        label="New"
                        sx={{
                          cursor: "pointer",
                          minWidth: "100px",
                          textOverflow: "unset",
                          overflow: "none",
                          bgcolor: "#ff4747",
                        }}
                      />
                    </MenuItem>
                    <MenuItem value="closed">
                      <Chip
                        label="Closed"
                        sx={{
                          cursor: "pointer",
                          minWidth: "100px",
                          textOverflow: "unset",
                          overflow: "none",
                          bgcolor: "green",
                        }}
                      />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item sx={{ mt: 5 }}>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mt: "-10px",
                }}
              >
                {state?.user?.profileImg ? (
                  <img
                    src={state?.user?.profileImg || null}
                    alt="ProfileImage"
                    className="support-Profile-image"
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(state?.user?.name?.toUpperCase())}
                    sx={{
                      fontSize: 15,
                      height: "40px",
                      width: "40px",
                      mb: "10px",
                      bgcolor: "rgb(255, 225, 53)",
                      color: "black",
                      fontWeight: "600",
                      mt: "8px",
                    }}
                  />
                )}

                <H4>{capitalizeFirstLetter(state?.user?.name)}</H4>

                <Grid></Grid>
                <Grid sx={{ ml: 5 }}>
                  <Typography sx={{ fontWeight: 500 }}>CreatedAt</Typography>
                  <Small sx={{ fontWeight: "light" }}>
                    {moment(state?.createdAt).format(CONSTANT.DATE_FORMATE)}
                  </Small>
                </Grid>
                <Grid sx={{ ml: 2 }}>
                  <Typography sx={{ fontWeight: 500 }}>UpdatedAt</Typography>
                  <Small sx={{ fontWeight: "light" }}>
                    {moment(state?.updatedAt).format(CONSTANT.DATE_FORMATE)}
                  </Small>
                </Grid>
                <Grid sx={{ ml: 2 }}>
                  <Typography sx={{ fontWeight: 500 }}>Post ID</Typography>
                  <Small sx={{ fontWeight: "light" }}>{state?._id}</Small>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Grid sx={{ mt: 5 }}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Box
              sx={{ overflowY: "auto", maxHeight: "500px", minHeight: "85%" }}
              ref={chatContainerRef}
            >
              <Stepper orientation="vertical" connector={stringAvatar}>
                {messageData.map((step, index) => (
                  <>
                    <Step key={step.message} active>
                      <Grid
                        container
                        sx={{ marginTop: "5px" }}
                        rowSpacing={1}
                        className={
                          step?.user?.role === "super_admin" ||
                          step?.user?.role === "admin"
                            ? "admin-reply-card-1"
                            : "user-reply-card-1"
                        }
                      >
                        {step?.user?.role === "super_admin" ||
                        step?.user?.role === "admin" ? (
                          <Grid
                            sx={{
                              display: "flex",
                              gap: 1,
                              alignItems: "center",
                            }}
                            className={
                              step?.user?.role === "super_admin" ||
                              step?.user?.role === "admin"
                                ? "admin-reply-card"
                                : "user-reply-card"
                            }
                          >
                            {step?.user?.profileImg ? (
                              <img
                                src={step?.user?.profileImg || null}
                                alt="ProfileImage"
                                className="support-Profile-image"
                              />
                            ) : (
                              <Avatar
                                {...stringAvatar(
                                  step?.user?.name?.toUpperCase()
                                )}
                                sx={{
                                  fontSize: 15,
                                  height: "40px",
                                  width: "40px",
                                  mb: "10px",
                                  bgcolor: "rgb(255, 225, 53)",
                                  color: "black",
                                  fontWeight: "600",
                                  mt: "10px",
                                }}
                              />
                            )}
                            <Typography
                              sx={{ color: "gray", fontSize: "12px", ml: 1 }}
                            >
                              {moment(step?.createdAt).format("LT")}
                            </Typography>
                            <Typography
                              sx={{ color: "gray", fontSize: "12px" }}
                            >
                              {moment(step?.createdAt).format(
                                CONSTANT.DATE_FORMATE
                              )}
                            </Typography>

                            <H4>{capitalizeFirstLetter(step?.user?.name)}</H4>
                          </Grid>
                        ) : (
                          <Grid
                            sx={{
                              display: "flex",
                              gap: 1,
                              alignItems: "center",
                            }}
                            className={
                              step?.user?.role === "super_admin" ||
                              step?.user?.role === "admin"
                                ? "admin-reply-card"
                                : "user-reply-card"
                            }
                          >
                            {step?.user?.profileImg ? (
                              <img
                                src={step?.user?.profileImg || null}
                                alt="ProfileImage"
                                className="support-Profile-image"
                              />
                            ) : (
                              <Avatar
                                {...stringAvatar(
                                  step?.user?.name?.toUpperCase()
                                )}
                                sx={{
                                  fontSize: 15,
                                  height: "40px",
                                  width: "40px",
                                  mb: "10px",
                                  bgcolor: "rgb(255, 225, 53)",
                                  color: "black",
                                  fontWeight: "600",
                                  mt: "10px",
                                }}
                              />
                            )}

                            <H4>{capitalizeFirstLetter(step?.user?.name)}</H4>

                            <Typography
                              sx={{ color: "gray", fontSize: "12px" }}
                            >
                              {moment(step?.createdAt).format(
                                CONSTANT.DATE_FORMATE
                              )}
                            </Typography>
                            <Typography
                              sx={{ color: "gray", fontSize: "12px", ml: 1 }}
                            >
                              {moment(step?.createdAt).format("LT")}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        sx={{
                          ml: 2.5,
                          backgroundColor: "#f7f7f7",
                          borderRadius: "5px",
                          borderLeft: "1px solid gray",
                          pl: "5px",
                          p: "7px",
                          width: "50%",
                        }}
                        className={
                          step?.user?.role === "super_admin" ||
                          step?.user?.role === "admin"
                            ? "admin-messages"
                            : "customer-messages"
                        }
                      >
                        {step?.message && (
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: step?.message,
                            }}
                          />
                        )}
                        {step?.attachment?.url && (
                          <img
                            src={step?.attachment?.url}
                            className="images"
                            alt=""
                          />
                        )}
                      </Grid>
                    </Step>
                    <Grid
                      sx={{
                        ml: 2.5,
                      }}
                    >
                      {messageData.length - 1 === index ? (
                        ""
                      ) : (
                        <Divider sx={{ mt: 2 }} />
                      )}
                    </Grid>
                  </>
                ))}
              </Stepper>
            </Box>

            <form noValidate onSubmit={handleSubmit}>
              <Typography sx={{ mt: 2, ml: 2, fontWeight: 600 }}>
                {fileName?.name}
              </Typography>
              <Grid
                container
                sx={{
                  ml: 2,
                  display: "flex",
                  alignItems: "end",
                  mt: "20px",
                  mb: "75px",
                }}
              >
                <Grid
                  item
                  lg={8}
                  md={8}
                  sm={8}
                  xs={12}
                  className="text-box-grid  input-support-thread"
                >
                  <InputEmoji
                    name="message"
                    type="text"
                    placeholder="Write a reply..."
                    value={values.message || ""}
                    onChange={handleEmojiInputChange}
                    minRows={6}
                    keepOpened
                    shouldReturn
                    onResize
                  />
                </Grid>

                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={4}
                  xs={12}
                  sx={{ display: "flex" }}
                  className="buttons-container"
                >
                  <Tooltip
                    title="Attachment"
                    placement="top-start"
                    sx={{
                      borderRadius: "5px",
                      height: "30px",
                      width: "30px",
                      padding: "2px",
                    }}
                    className="attachment-send-button"
                  >
                    <button
                      type="button"
                      onClick={() => inputFile.current.click()}
                      className="thread-button button"
                    >
                      <AttachFileIcon color="black" sx={{ mt: 0.5 }} />
                    </button>
                  </Tooltip>

                  <LoadingButton
                    type="submit"
                    loading={buttonLoading}
                    loadingPosition="start"
                    fullWidth
                    variant="contained"
                    className="button thread-button attachment-send-button"
                  >
                    Send
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>

            <input
              type="file"
              onChange={handleAttachment}
              ref={inputFile}
              hidden
            />
          </CardContent>
        </Card>
        {backdropOpen && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Grid>
    </>
  );
};
export default SupportThread;
