import React, { useEffect, useState } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { ukoTheme } from "./theme";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";

const App = () => {
  const allPages = useRoutes(routes);

  const [roleAccesses, setRoleAccesses] = useState([]);

  useEffect(() => {
    const accesses = localStorage.getItem(btoa("accesses"));
    setRoleAccesses(atob(accesses));
  }, []);

  const appTheme = ukoTheme();

  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif",
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <Toaster toastOptions={toasterOptions} />
        {allPages}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
