import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { Button, Grid, Typography } from "@mui/material";
import routes from "../../routes";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const SidebarLink = styled(Link)`
  display: flex;
  color: black;
  justify-content: space-between;
  align-items: center;
  margin: 5px;

  list-style: none;
  height: 50px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: lightgrey;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }

  &.active {
    background: #44564a;
    color: white;
    border-radius: 5px;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
  font-size: 16px;
`;

const DropdownLink = styled(Link)`
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: black;
  font-size: 14px;

  &:hover {
    background: lightgrey;
    cursor: pointer;
  }
`;

const SubMenu = ({ nav }) => {
  const location = useLocation();
  const [subNav, setSubNav] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);

  const currentUrl = window.location.href;

  const showSubNav = (event) => {
    event.preventDefault();
    setSubNav(!subNav);
  };

  return (
    <>
      <SidebarLink
        to={nav.path}
        className={location.pathname === nav.path || activeMenu ? "active" : ""}
      >
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "50%",
          }}
        >
          {nav.Icon && (
            <nav.Icon
              sx={{
                ":hover": {
                  color:
                    location.pathname === nav.path || activeMenu
                      ? "#ffffff"
                      : "#44564a",
                },
                color:
                  location.pathname === nav.path || activeMenu
                    ? "#ffffff"
                    : "#363636",

                ml: "25px",
              }}
            />
          )}
          <SidebarLabel
            style={{
              color:
                location.pathname === nav.path || activeMenu
                  ? "#ffffff"
                  : "#363636",

              display: "flex",
              alignItems: "center",
            }}
          >
            {nav.title}

            {nav?.subMenuItems && (
              <Button
                onClick={(e) => showSubNav(e)}
                sx={{
                  padding: "6px 0 6px 0",
                  minWidth: "50px",
                  marginLeft: "5px",
                }}
              >
                {subNav ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>
            )}
          </SidebarLabel>
        </Typography>
      </SidebarLink>

      {nav.subNav && subNav && (
        <Grid sx={{ display: "flex", justifyContent: "end" }}>
          {nav.subMenuItems.map((subNavItem, index) => (
            <SidebarLink
              to={subNavItem.path}
              style={{
                display: "flex",
                alignItems: "center",
                height: "35px",
                padding: "1px 0 0 10px",
                color: "black",
                borderTopLeftRadius: "24px",
                borderBottomLeftRadius: "24px",
                justifyContent: "flex-start",
                width: "190px",
              }}
              className={
                location.pathname === subNavItem.path || activeMenu
                  ? "active"
                  : ""
              }
            >
              {subNavItem.Icon && (
                <subNavItem.Icon
                  sx={{
                    ":hover": {
                      color:
                        location.pathname === subNavItem.path || activeMenu
                          ? "#ffffff"
                          : "#363636",
                    },
                    color:
                      location.pathname === subNavItem.path || activeMenu
                        ? "#ffffff"
                        : "#363636",

                    ml: "25px",
                    height: "13px",
                  }}
                />
              )}
              <SidebarLabel
                style={{
                  color:
                    location.pathname === subNavItem.path || activeMenu
                      ? "#ffffff"
                      : "#363636",
                }}
              >
                {subNavItem.title}
              </SidebarLabel>
            </SidebarLink>
          ))}
        </Grid>
      )}
    </>
  );
};

export default SubMenu;
