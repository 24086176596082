export const BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;

export const API_CONSTANT = {
  /* Login Service*/
  SIGN_UP_DATA: "api/user/signup",
  USER_VERIFICATION: "api/user/verifyOtp",
  LOGIN: "api/user/signinAdmin",
  FORGET_PASSWORD: "api/user/forgetPassword",
  PASSWORD_VERIFICATION: "api/user/isValidateForResetPassword",
  RESET_PASSWORD: "api/user/resetPassword",

  /* User Service*/
  GET_ALL_USER: "api/user/getAllUser",
  GET_USER_BY_ID: "api/user/getUserById/",
  UPDATE_USER_BY_ID: "api/user/editUserDetails",
  DELETE_USER_BY_ID: "api/user/deleteUserDetails/",
  GET_USER_ACTIVITY_BY_ID: "api/userSessiontime/getUserSession?user_id=",
  GET_ALL_USERACITIVITY: "api/userSessiontime/getAllUserSession",

  /*----------For Admin-------------*/
  GET_ALL_HISTORY: "api/user/getallhistory",
  GET_ALL_FEEDBACK: "api/feedback/getAllFeedback",
  FEEDBAck_UPDATE: "api/feedback/updateFeedbackStatusById",

  /* Ingredient Service*/
  FIND_ALL_INGREDIENT: "api/ingredient/findIngredient",
  GET_INGREDIENT_BY_ID: "api/ingredient/getIngredientById/",
  UPDATE_INGREDIENT_BY_ID: "api/ingredient/updateIngredientById/",
  DELETE_INGREDIENT_BY_ID: "api/ingredient/deleteIngredientById/",

  /* Support Service*/
  CREATE_THREAD: "api/thread/createThread",
  GET_THREAD_BY_TICKET: "api/thread/getThreadByTicket?ticketNo=",
  UPDATE_SUPPORT_STATUS: "api/feedback/updateSupportStatusById",
  SUPPORT_LIST: "api/feedback/getAllSupport",
};

export const MESSAGE = {
  LOGIN_SUCCESS: "Login Successfully",
  TITLE: "Title is Required!",
  NAME: "Name is Required!",
  EMAIL_VALID: "Must be a valid email",
  EMAIL_REQUIRED: "Email is required",
  PASSWORD_MINIMUM: "Password should be of minimum 6 characters length",
  PASSWORD_REQUIRED: "Password is required",
  NEW_PASSWORD_REQUIRED: "New Password is required",
  CURRENT_PASSWORD_REQUIRED: "Current Password is required",
  CONFIRM_PASSWORD_MINIMUM:
    "ConfirmPassword should be of minimum 6 characters length",
  CONFIRM_PASSWORD_REQUIRED: "Confirm Password is required",
  PASSWORD_MATCH: "Password is not match",
  PHONE_NO_MINIMUM: "Phone No should be of minimum 12 characters length",
  PHONE_NO_MAXIMUM: "Phone No should be of maximum 12 characters length",
  OTP: "Otp code is required",
  OTP_MIN: "Otp must be a 4 digit",
  ROLE: "Role is Required!",
  NETWORK_ERROR: "Network Error occurred",
  PAYMENT_NETWORK_ERROR: "Payment already done successfully!",
  POST_NOTES_PLACEHOLDER:
    "Hi, Please message if anyone traveling on June 8th Hyderabad to Melbourne, need assistance.Thanks.",
  TITLE: "Title is required",
  QUESTION: "Question is Required!",
  ANSWER: "Answer is Required!",
  MAXIMUM_USER: "User count is required",
  NO_FILE_SELECT: "No file selected",
  ROLE_NAME: "Role Name is Required!",
  ROLE_KEY: "Role Key is Required!",
};

export const PAGE_CONSTANT = {
  DASHBOARD: "Dashboard",
  USER_LIST: "Users",
  INGREDIENTS_CHECKER: "IC",
  POSTS: "Posts",
  POST_DETAIL: "Post Detail",
  CHAT_CONVERSATION: "Chat Conversation",
  CUSTOMERS: "Customers",
  FEED_BACK_LIST: "Feedback",
  SUPPORT: "Support",
  SUPPORT_THREAD: "Support Thread",
  TRANSACTION_LIST: "Transaction",
  TRANSACTION_DETAIL: "Transaction Detail",
  REFUND_LIST: "Refund",
  REFUND_DETAIL: "Refund Detail",
  NOTIFICATION: "Notification",
  NOTIFICATION_SEND_TABLE: "Notification Send",
  FAQS_LIST: "FAQ's",
  EDIT_USER: "Edit User",
  ADD_MEMBER: "Add New Members",
  PROFILE: "Profile",
  USER_LIST: "User List",
  SETTINGS: "Settings",
  ROLE_MANAGEMENT: "Role Management",
};
export const CONSTANT = {
  NULL_STRING: "",
  DASH_STRING: "-",
  UNDEFINED: "undefined",
  CARRYPARCEL: "carryParcel",
  TRAVEL_PARTNER: "travelPartner",
  CARRY_PARCEL: "Carry Parcel",
  TRAVELER_PARTNER: "Travel Partner",
  DATE_FORMATE: "Do MMM, YYYY",
  SEARCH_POST: "Search Post...",
  SEARCH_STAFF: "Search Staff...",
  SEARCH_CUSTOMER: "Search Customer...",
  SOMETHING_WRONG: "Something went wrong",
  PUBLISHED: "published",
  UNPUBLISHED: "unpublished",
  SUCCESS: "success",
  PRIMARY: "primary",
  DEFAULT: "default",
  SECONDARY: "secondary",
  WARNING: "warning",
  DESC: "desc",
  ASC: "asc",
  ERROR: "error",
  REFUND: "Refund",
  REFUNDED: "refunded",
  ACTIVE: "active",
  CSV: "csv",
  PDF: "pdf",
  ASCENDING: "ascending",
  DESCENDING: "descending",
  SELECTED_MENU: ["weekly", "monthly", "yearly"],
  NOTIFICATION_SELECTED_MENU: ["send all customers", "custom selection"],
  MENU_FORMAT: ["This Week", "This Month", "This Year"],
  WEEKLY: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  NOTIFICATION_HISTORY: "notification-history",
  BUMP_REFUND: "Bump is not refundable",
  PDF_CUSTOMER: "customers",
  PDF_TRANSACTION: "transaction",
  PDF_REFUND: "refund",
  PDF_NOTIFICATION_HISTORY: "notification-history",
  REFUND_REASON_NOTE:
    "Please copy a reason from above and fill in the field below",

  MONTHLY: Array.from({ length: 31 }, (_, index) => (index + 1).toString()),
  YEARLY: Array.from({ length: 12 }, (_, index) =>
    new Date(2000, index, 1).toLocaleString("default", { month: "short" })
  ),

  ROLE_LIST: [
    { id: "super_admin", label: "Super Admin" },
    { id: "admin", label: "Admin" },
    { id: "customer", label: "Customer" },
  ],
  STATUS_LIST: [
    { id: "active", label: "Active" },
    { id: "deactive", label: "Deactive" },
    { id: "suspend", label: "Suspend" },
  ],

  ROLE_MANAGEMENT_LIST: [
    { key: "dashboard", value: "Dashboard" },
    { key: "posts", value: "Post" },
    { key: "staff", value: "Staff" },
    { key: "customers", value: "Customer" },
    { key: "feedback", value: "Feedback" },
    { key: "support", value: "Support" },
    { key: "transaction", value: "Transaction" },
    { key: "refund", value: "Refund" },
    { key: "notification", value: "Notification" },
    { key: "faqs", value: "Faq's" },
    { key: "settings", value: "Setting" },
    { key: "role_access", value: "Role Access" },
  ],

  DELETE_CONFIRMATION: "Are you sure to delete record?",
  VALUE_COPIE: "Value copied to clipboard: ",
  FAILED_COPIE: "Failed to copy value: ",
  SERVICE_TAX: 12,
  GST: 8,
};
