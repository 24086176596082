import { LoadingButton } from "@mui/lab";
import { Box, Card, Typography } from "@mui/material";
import FlexBox from "../../components/FlexBox";
import LightTextField from "../../components/LightTextField";
import { H1, Small } from "../../components/Typography";
import { TextFieldWrapper } from "../../components/authentication/StyledComponents";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { MESSAGE, PAGE_CONSTANT } from "../../utils/constant.jsx";
import logo from "../../Image/logo/Ingredients_Checker.png";
import "../../CommonCss/Button.css";
import userService from "../../service/user.service";
import {
  deleteStatus,
  notifyError,
  notifySuccess,
} from "../../utils/common.service";

const ResetPassword = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const emailData = localStorage.getItem(btoa("email"));
  const email = atob(emailData);

  useEffect(() => {
    document.title = PAGE_CONSTANT.INGREDIENTS_CHECKER;
  }, []);

  const initialValues = {
    password: "",
    submit: null,
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, MESSAGE.PASSWORD_MINIMUM)
      .required(MESSAGE.PASSWORD_REQUIRED),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        try {
          setLoading(true);
          userService
            .resetPassword({
              email: email,
              password: values.password,
            })
            .then((res) => {
              const { status, message } = res;

              if (status === 200) {
                setLoading(false);
                notifySuccess(deleteStatus(res));
                localStorage.removeItem(btoa("email"));
                navigate("/login");
              } else {
                setLoading(false);
                notifyError(deleteStatus(res) || MESSAGE.NETWORK_ERROR);
              }
            })
            .catch((err) => {
              setLoading(false);
            });
        } catch (err) {
          setLoading(false);
          notifyError(err ? err?.response?.data?.message : err?.message);
        }
      },
    });

  return (
    <FlexBox
      height="100vh"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <Card sx={{ padding: 4, maxWidth: 600, marginTop: 4, boxShadow: 1 }}>
        <FlexBox
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mb={5}
        >
          <Box width={100} mb={1}>
            <img src={logo} width="100%" alt="Ingredients_Checker Logo" />
          </Box>
          <H1 fontSize={24} fontWeight={700}>
            Reset your password
          </H1>
        </FlexBox>

        <FlexBox justifyContent="space-between" flexWrap="wrap" my={2}>
          <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Typography>
              <strong>{email}</strong>
            </Typography>

            <TextFieldWrapper sx={{ mt: 2, width: "100%" }}>
              <LightTextField
                fullWidth
                name="password"
                type="password"
                label="New Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password || ""}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </TextFieldWrapper>

            <Box sx={{ mt: 4 }}>
              <LoadingButton
                type="submit"
                loading={loading}
                loadingPosition="start"
                variant="contained"
                fullWidth
                className="button"
              >
                Reset Password
              </LoadingButton>
            </Box>
          </form>

          <Small margin="auto" mt={3} color="text.disabled">
            Don't have an account?{" "}
            <Link to="/register">
              <Small color="primary.main">Create an account</Small>
            </Link>
          </Small>
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default ResetPassword;
