import React from "react";
import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import "../../CommonCss/Button.css";

import ScrollBar from "simplebar-react";
import { isArrayEmpty } from "../../utils/common.service";
import { BootstrapDialogTitle } from "../../components/Layouts/Modal/CustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import "../../CommonCss/Button.css";
import { H3, Small } from "../../components/Typography";
import moment from "moment";
import { CONSTANT } from "../../utils/constant";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    fontSize: "14px",
    backgroundColor: "black",
    color: "white",
  },
});
function HistoryPage(props) {
  const {
    closeModal,
    data,
    historyId,
    isSort = false,
    createSortHandler,
  } = props;
  let res = data.find((obj) => obj._id === historyId);

  return (
    <Grid id="history">
      <BootstrapDialogTitle
        sx={{
          position: "sticky",
          top: "-10px",
          zIndex: "999",
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "space-between",
          ml: "-8px",
          mb: "10px",
          pb: "0",
          pt: "14px",
        }}
      >
        <h3>History</h3>

        <IconButton>
          <CloseIcon onClick={closeModal} />
        </IconButton>
      </BootstrapDialogTitle>

      <ScrollBar sx={{ overflowY: "auto", maxHeight: "calc(100vh - 172px)" }}>
        <Grid
          container
          sx={{
            padding: "0 20px",
          }}
        >
          <Grid xs={3}>
            <Typography sx={{ color: "#4f4f4f" }}>Name</Typography>
            <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
              {res?.username || "-"}
            </Typography>
          </Grid>
          <Grid xs={3}>
            <Typography sx={{ color: "#4f4f4f" }}>HistoryID</Typography>
            <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
              {res?._id || "-"}
            </Typography>
          </Grid>

          <Grid xs={3}>
            <Typography sx={{ color: "#4f4f4f" }}>CreatedAt</Typography>
            <Typography sx={{ fontWeight: 600 }}>
              {res?.createdAt
                ? moment(res?.createdAt).format(CONSTANT.DATE_FORMATE) +
                  " [" +
                  moment(res?.createdAt, "HH:mm").format("hh:mm A") +
                  "]"
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ color: "#1d2438" }}>
            <Typography sx={{ color: "#4f4f4f" }}>Email</Typography>
            <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
              {res?.email || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ mt: "30px" }}>
            <Typography sx={{ color: "#4f4f4f" }}> Product Name</Typography>
            <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
              {res?.answer[res?.answer.length - 1]?.product_name || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ mt: "30px" }}>
            <Typography sx={{ color: "#4f4f4f" }}> Category</Typography>
            <Typography sx={{ fontWeight: 600 }} className="letter-spacing">
              {res?.answer[res?.answer.length - 1]?.category || "-"}
            </Typography>
          </Grid>

          <Grid item xs={16} sx={{ mt: "30px" }}>
            <Typography sx={{ color: "#4f4f4f" }}> Question</Typography>
            <CustomWidthTooltip title={res?.question || "-"}>
              <Typography
                sx={{ fontWeight: 600 }}
                className="letter-spacing ellipse-question"
              >
                {res?.question || "-"}
              </Typography>
            </CustomWidthTooltip>
          </Grid>
        </Grid>

        <Divider sx={{ mt: "20px", padding: "0 20px" }} />
        {res?.answer.length > 1 ? (
          <Table
            sx={{
              borderSpacing: "0 1rem",
              borderCollapse: "separate",
              padding: "0 20px",
            }}
          >
            <TableHead className="sticky-table-header">
              <TableRow>
                <TableCell
                  sx={{
                    paddingY: 0,
                    fontSize: 13,
                    width: "230px",
                    fontWeight: 600,
                    borderBottom: 0,
                    textAlign: "left",
                    color: "text.primary",

                    "& .MuiTableSortLabel-icon": {
                      color: "black !important",
                    },
                    "&:last-child": { textAlign: "left" },
                    "&:hover": { color: "black" },
                    "&:focus": { color: "black" },
                  }}
                >
                  Title
                </TableCell>
                <TableCell
                  sx={{
                    paddingY: 0,
                    fontSize: 13,
                    fontWeight: 600,
                    borderBottom: 0,
                    textAlign: "left",
                    color: "text.primary",

                    "& .MuiTableSortLabel-icon": {
                      color: "black !important",
                    },
                    "&:last-child": { textAlign: "left" },
                    "&:hover": { color: "black" },
                    "&:focus": { color: "black" },
                  }}
                >
                  Description
                </TableCell>
                <TableCell
                  sx={{
                    paddingY: 0,
                    fontSize: 13,
                    width: "130px",
                    fontWeight: 600,
                    borderBottom: 0,
                    textAlign: "left",
                    color: "text.primary",

                    "& .MuiTableSortLabel-icon": {
                      color: "black !important",
                    },
                    "&:last-child": { textAlign: "left" },
                    "&:hover": { color: "black" },
                    "&:focus": { color: "black" },
                  }}
                >
                  Health Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {res?.answer?.map((row, i) => {
                return (
                  <>
                    {row?.title ? (
                      <TableRow
                        key={"row_" + i}
                        sx={{
                          backgroundColor: "background.paper",
                          // cursor: isRowClick ? "pointer" : "unset",
                          "& td:first-of-type": {
                            borderLeft: "1px solid",
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px",
                          },
                          "& td:last-of-type": {
                            textAlign: "justify",
                            borderRight: "1px solid",
                            borderTopRightRadius: "8px",
                            borderBottomRightRadius: "8px",
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            width: "50px",
                            fontSize: 13,
                            fontWeight: 500,
                            color: "#1d2438",
                            borderTop: "1px solid",
                            borderBottom: "1px solid",
                          }}
                        >
                          {row?.title || "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 13,
                            fontWeight: 500,
                            color: "#1d2438",
                            borderTop: "1px solid",
                            borderBottom: "1px solid",
                          }}
                        >
                          {row?.description || "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 13,
                            fontWeight: 500,
                            color: "#1d2438",
                            borderTop: "1px solid",
                            borderBottom: "1px solid",
                            backgroundColor:
                              row?.health_status === "healthy"
                                ? "#0fb70f"
                                : row?.health_status === "unhealthy"
                                ? "#ff6262"
                                : "transparent",
                          }}
                        >
                          {row?.health_status}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <H3 sx={{ textAlign: "center", color: "red" }}>
            This user has not any Answer Data!
          </H3>
        )}
        {res?.imageUrl && (
          <Grid item xs={3} sx={{ mt: "30px", textAlign: "center" }}>
            <img src={res?.imageUrl} height="400px" width="450px" />
          </Grid>
        )}
      </ScrollBar>
    </Grid>
  );
}

export default HistoryPage;
