import React, { useEffect, useState, useContext } from "react";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import FlexBox from "../../components/FlexBox";
import SearchInput from "../../components/SearchInput";
import useTitle from "../../hooks/useTitle";
import "../../CommonCss/Button.css";
import userService from "../../service/user.service";
import {
  StyledPagination,
  capitalizeFirstLetter,
  convertToUTCString,
  notifyError,
  notifySuccess,
  returnError,
  showConfirmDialog,
  stringAvatar,
  timeDifference,
} from "../../utils/common.service";

import { CONSTANT, MESSAGE, PAGE_CONSTANT } from "../../utils/constant";
import { Small } from "../../components/Typography";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Stack } from "@mui/system";
import "../users/UserList.css";
import NodataFound from "../../components/Layouts/NodataFound";
import { BackdropContext } from "../../contexts/BackdropContext";
import FilterMenu from "../../components/filter/filterMenu";
import moment from "moment";
import CustomizationDialog from "../../components/Layouts/Modal/CustomizationModal";
import HistoryPage from "../users/HistoryPage";
import HistoryTable from "./ActivityTable";
import { CopyAll } from "@mui/icons-material";
import ActivityTable from "./ActivityTable";

const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const Activity = () => {
  const { backdropOpen, setBackdropOpen } = useContext(BackdropContext);

  const [pageObj, setPageObj] = useState({
    pageCount: 1,
    limit: 20,
    search: "",
  });
  useTitle("Activity");
  const [activityAll, setActivityAll] = useState([]);
  const [totalRecord, setTotalRecord] = useState("");
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [activityId, setActivityId] = useState("");
  useEffect(() => {
    document.title = PAGE_CONSTANT.USER_LIST;
  }, []);

  useEffect(() => {
    fetchData({ ...pageObj });
  }, [pageObj]);

  const UserListColumnShape = [
    {
      Header: "SessionId",
      accessor: "_id",
      minWidth: 70,
      Cell: ({ value }) => (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <Small color="text.primary" className="ellipse-id">
              <Tooltip title={value || "-"} placement="top-start">
                {value || "-"}
              </Tooltip>
            </Small>
          </FlexBox>
        </FlexBox>
      ),
    },
    {
      Header: "UserId",
      accessor: "user_id",
      minWidth: 70,
      Cell: ({ value }) => (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <Small color="text.primary" className="ellipse-id">
              <Tooltip title={value || "-"} placement="top-start">
                {value || "-"}
              </Tooltip>
            </Small>
          </FlexBox>
        </FlexBox>
      ),
    },
    {
      Header: "Location",
      accessor: "location",
      minWidth: 120,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },
    {
      Header: "Country",
      accessor: "country",
      minWidth: 50,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },
    {
      Header: "Timezone",
      accessor: "timezone",
      minWidth: 60,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },
    {
      Header: "Start Time",
      accessor: "starttime",
      minWidth: 110,
      Cell: ({ value }) => {
        let time = convertToUTCString(value);
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {moment(time).format(CONSTANT.DATE_FORMATE)}
            </Small>{" "}
            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {moment(time).format("LT")}
            </Grid>
          </>
        );
      },
    },
    {
      Header: "End Time",
      accessor: "endtime",
      minWidth: 110,
      Cell: ({ value }) => {
        let time = convertToUTCString(value);

        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {value !== "" ? moment(time).format(CONSTANT.DATE_FORMATE) : "-"}
            </Small>{" "}
            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {value !== "" ? moment(time).format("LT") : "-"}
            </Grid>
          </>
        );
      },
    },
    {
      Header: "Total Spent",
      accessor: "totalTime",
      minWidth: 110,
      Cell: ({ row }) => {
        const starttime = convertToUTCString(row?.original?.starttime);
        const endtime = convertToUTCString(row?.original?.endtime);
        const { minutes, seconds } = timeDifference(starttime, endtime);
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {minutes} min
            </Small>{" "}
            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {seconds} sec
            </Grid>
          </>
        );
      },
    },
    {
      Header: "Created Date",
      accessor: "createdAt",
      minWidth: 130,
      Cell: ({ value }) => {
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {moment(value).format(CONSTANT.DATE_FORMATE)}
            </Small>{" "}
            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {moment(value).format("LT")}
            </Grid>
          </>
        );
      },
    },

    {
      Header: "Updated Date",
      accessor: "updatedAt",
      minWidth: 130,
      Cell: ({ value }) => {
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {moment(value).format(CONSTANT.DATE_FORMATE)}
            </Small>

            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {moment(value).format("LT")}
            </Grid>
          </>
        );
      },
    },
  ];

  const fetchData = async (obj) => {
    try {
      setBackdropOpen(true);
      let data = {
        ...obj,
        pageCount: obj.pageCount - 1,
      };

      userService
        .fetchAllUserActivity({ ...data })
        .then((res) => {
          if (res.status === 200) {
            setBackdropOpen(false);
            setActivityAll(res?.data || []);
            setTotalRecord(res?.totalRecords || 0);
            notifySuccess(res.message);
          } else {
            setTotalRecord(res?.totalRecords || 0);
            setActivityAll([]);
            setBackdropOpen(false);
            notifyError(res.message);
          }
        })
        .catch((err) => {
          setBackdropOpen(false);
          notifyError(returnError(err));
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(returnError(err));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(event.target.value);
    }
  };
  function copyValue(value) {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        notifySuccess(CONSTANT.VALUE_COPIE + value);
      })
      .catch((error) => {
        notifyError(CONSTANT.FAILED_COPIE + error);
      });
  }

  const handleSearch = (value) => {
    setPageObj({ ...pageObj, search: value, pageCount: 1 });
  };

  const handleChange = (_event, currentPage) => {
    setPageObj({ ...pageObj, pageCount: currentPage });
  };

  const handleRowsChange = (event, currentPage) => {
    setPageObj({
      ...pageObj,
      pageCount: 1,
      limit: parseInt(event.target.value),
    });
  };

  const handleOpenHistoryModal = (_id) => {
    setBackdropOpen(true);
    setTimeout(() => {
      setActivityId(_id);
      setOpenHistoryModal(true);
      setBackdropOpen(false);
    }, 1000);
  };

  const handleCloseHistoryModal = () => {
    setActivityId("");
    setOpenHistoryModal(false);
  };

  return (
    <Box pt={2} pb={4}>
      <Grid
        sx={{ display: "flex", justifyContent: "end" }}
        className="style-box"
      ></Grid>

      <StyledFlexBox sx={{ mt: 2 }} className="style-box">
        <SearchInput
          sx={{ width: "inherit" }}
          placeholder="Search users..."
          onKeyPress={handleKeyPress}
        />
        <FilterMenu
          setPageObj={setPageObj}
          pageObj={pageObj}
          showSort={false}
          showStatus={false}
          showRangePicker={false}
          showExport={false}
        />
      </StyledFlexBox>

      <Grid>
        <ActivityTable
          isSort={false}
          pageObj={pageObj}
          columnShape={UserListColumnShape}
          data={activityAll}
          hidePagination={false}
        />
        {!backdropOpen &&
          (!activityAll || (activityAll.length <= 0 && <NodataFound />))}
      </Grid>

      {backdropOpen && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            Total Record:
          </Typography>

          <Small
            sx={{
              borderRadius: 10,
              padding: "6px 30px 6px 30px",
              color: "white",
              backgroundColor: "#44564a",
            }}
          >
            {totalRecord || 0}
          </Small>
        </Grid>

        {activityAll?.length > 0 && (
          <Stack marginY={1}>
            <StyledPagination
              count={
                (totalRecord && Math.ceil(totalRecord / pageObj.limit)) || 0
              }
              shape="rounded"
              page={pageObj?.pageCount || 0}
              onChange={handleChange}
              onRowsPerPageChange={handleRowsChange}
            />
          </Stack>
        )}
      </Grid>

      {/* <CustomizationDialog
        open={openHistoryModal}
        handleClose={handleCloseHistoryModal}
        children={
          <HistoryPage
            closeModal={handleCloseHistoryModal}
            data={activityAll}
            pageObj={pageObj}
            activityId={activityId}
            isSort={false}
          />
        }
      /> */}
    </Box>
  );
};

export default Activity;
