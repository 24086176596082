import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import LightTextField from "../../components/LightTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import "../../CommonCss/Button.css";
import { MESSAGE } from "../../utils/constant.jsx";
import userService from "../../service/user.service";
import {
  deleteStatus,
  isArrayEmpty,
  notifyError,
  notifySuccess,
  returnError,
} from "../../utils/common.service";
import { BootstrapDialogTitle } from "../../components/Layouts/Modal/CustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import "../../CommonCss/Button.css";

const UserForm = (props) => {
  const { editData, closeModal, setPageObj, pageObj, id } = props;

  const [buttonLoading, setButtonLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState([]);

  const initialValues = {
    _id: id,
    profilePicture: null,
    firstName: editData?.firstName,
    lastName: editData?.lastName,
    username: editData?.username,
    email: editData?.email,
    gender: editData?.gender,
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(MESSAGE.NAME),
    email: Yup.string().email().required(MESSAGE.EMAIL_REQUIRED),
  });

  const handleAttachment = (e) => {
    setFile([...file, e.target.files[0]]);
    const fileData = e.target.files[0];
    setFileName(fileData);
  };

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async () => {
        try {
          setButtonLoading(true);

          const formData = new FormData();
          formData.append("user_id", id);
          formData.append("profilePicture", file[0]);
          formData.append("firstName", values.firstName);
          formData.append("lastName", values.lastName);
          formData.append("username", values.username);
          formData.append("email", values.email);
          formData.append("gender", values.gender);

          userService
            .updateUserById(formData)
            .then((res) => {
              if (res.status === 200) {
                setButtonLoading(false);
                notifySuccess(deleteStatus(res));
                setFile("");
                setFileName("");
                setPageObj({
                  ...pageObj,
                  refresh: Date.now(),
                });

                closeModal();
              } else {
                setButtonLoading(false);
                notifySuccess(deleteStatus(res));
              }
            })
            .catch((err) => {
              setButtonLoading(false);
              notifyError(err ? err?.response?.data?.message : err?.message);
            });
        } catch (err) {
          setButtonLoading(false);
          notifyError(err ? err?.response?.data?.message : err?.message);
        }
      },
    });

  return (
    <form onSubmit={handleSubmit} enctype="multipart/form-data">
      <BootstrapDialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-20px",
          marginLeft: "-8px",
          marginBottom: "-10px",
        }}
      >
        <h3>Update User</h3>

        <IconButton>
          <CloseIcon onClick={closeModal} />
        </IconButton>
      </BootstrapDialogTitle>

      <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 172px)" }}>
        <Grid
          rowSpacing={{ xs: 3 }}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          container
          sx={{
            overflow: "auto",
          }}
          height={{ xs: "400px", lg: "300px" }}
        >
          <Grid item xs={12} md={6} lg={4}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Profile Image
            </Typography>
            <LightTextField
              fullWidth
              name="profilePicture"
              type="file"
              placeholder="Upload Image"
              onChange={handleAttachment}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              First Name
            </Typography>
            <LightTextField
              fullWidth
              name="firstName"
              type="text"
              placeholder="First Name"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Email
            </Typography>
            <LightTextField
              fullWidth
              name="email"
              type="email"
              placeholder="Email Address"
              value={values.email}
              onChange={handleChange}
              InputProps={{
                readOnly: true,
              }}
              disabled
              sx={{
                backgroundColor: "#e5eaf2",
                color: "black",
                borderRadius: "9px",
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Last Name
            </Typography>
            <LightTextField
              fullWidth
              name="lastName"
              type="text"
              placeholder="Last Name"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              User Name
            </Typography>
            <LightTextField
              fullWidth
              name="username"
              type="text"
              placeholder="User Name"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Typography
              as="h5"
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                mb: "12px",
              }}
            >
              Gender
            </Typography>
            <Grid item xs={12} md={12} lg={12}>
              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  label="Gender"
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  error={Boolean(touched.gender && errors.gender)}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              sx={{ maxWidth: "200px" }}
              type="submit"
              loading={buttonLoading}
              loadingPosition="start"
              variant="contained"
              fullWidth
              className="button"
            >
              Update User
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default UserForm;
