import { Box, Card, Grid, Skeleton, Typography, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Icons from "../../../icons/sidebar";
import React, { useContext } from "react";
import AnimatedNumbers from "react-animated-numbers";
import { BackdropContext } from "../../../contexts/BackdropContext";
import { formatCount, formatCountString } from "../../../utils/common.service";
import "./TransactionCard.css";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "287px",
    borderRadius: theme.spacing(2),
    backgroundColor: "#F5F7FA",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    justifyContent: "space-between",
    marginRight: "14px",
  },
  icon: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    borderRadius: "100%",
    width: 62,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  icon1: {
    color: theme.palette.primary.purple,
    backgroundColor: alpha(theme.palette.primary.purple, 0.2),
    borderRadius: "100%",
    width: 62,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  icon2: {
    color: theme.palette.primary.red,
    backgroundColor: alpha(theme.palette.primary.red, 0.2),
    borderRadius: "100%",
    width: 62,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  icon3: {
    backgroundColor: "#f3f4f9",
    borderRadius: "100%",
    width: 62,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  title: {
    height: "60px",
    fontSize: "37px",
    fontWeight: "600",
    marginBottom: "19px",
    marginBlockStart: "14px",
    paddingInlineStart: "17px",
  },

  box: {
    display: "flex",
    justifyContent: "space-between",

    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",

    padding: theme.spacing(2),
    backgroundColor: "#FFFFFF",

    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  count: {
    backgroundColor: "	#f5f5f5	",
    borderRadius: theme.spacing(1),
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    width: "70px",
    fontWeight: 600,
    color: "black",
  },
  label: {
    color: "#6C757D",
    maxWidth: "60px",
    fontSize: 12,
  },
}));

const CardBox = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item md={12} xs={12}>
          <Card
            sx={{
              display: "flex",
              border: "none",
              borderRadius: "10px",
              backgroundColor: "#f3f4f9",
            }}
          >
            <Grid
              container
              rowSpacing={{ xs: 3, sm: 3, md: 3, lg: 1 }}
              columnSpacing={{ xs: 1, sm: 3, md: 1, lg: 1 }}
            >
              <Grid
                item
                xs={12}
                lg={3}
                md={6}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Card className={classes.card}>
                  <Box>
                    <Box className={classes.header}>
                      <Typography
                        variant="h6"
                        className={classes.title}
                        sx={{
                          color: "#2499EF",
                          textShadow: "2px 4px 3px lightblue",
                          display: "flex",
                        }}
                      >
                        <Skeleton animation="wave" width={"140px"} />
                      </Typography>

                      <span className={classes.icon}>
                        <Skeleton
                          animation="wave"
                          variant="circular"
                          width={"62px"}
                          height={"60px"}
                        />
                      </span>
                    </Box>
                    <Box
                      sx={{
                        marginBlockStart: "-45px",
                        marginInlineStart: "5px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          padding: "10px",
                          fontSize: "20px",
                          width: "140px",
                        }}
                      >
                        <Skeleton animation="wave" />
                      </Typography>
                    </Box>
                    <Box className={classes.box}>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          <Skeleton animation="wave" />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: "5px" }}
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          <Skeleton animation="wave" />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: "6px" }}
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          <Skeleton animation="wave" />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: "6px" }}
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                lg={3}
                md={6}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Card className={classes.card}>
                  <Box>
                    <Box className={classes.header}>
                      <Typography
                        variant="h6"
                        className={classes.title}
                        sx={{
                          color: "#2499EF",
                          textShadow: "2px 4px 3px lightblue",
                          display: "flex",
                        }}
                      >
                        <Skeleton animation="wave" width={"140px"} />
                      </Typography>

                      <span className={classes.icon1}>
                        <Skeleton
                          animation="wave"
                          variant="circular"
                          width={"62px"}
                          height={"60px"}
                        />
                      </span>
                    </Box>
                    <Box
                      sx={{
                        marginBlockStart: "-45px",
                        marginInlineStart: "5px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          padding: "10px",
                          fontSize: "20px",
                          width: "140px",
                        }}
                      >
                        <Skeleton animation="wave" />
                      </Typography>
                    </Box>
                    <Box className={classes.box}>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          <Skeleton animation="wave" />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: "5px" }}
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          <Skeleton animation="wave" />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: "6px" }}
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          <Skeleton animation="wave" />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: "6px" }}
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                lg={3}
                md={6}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Card className={classes.card}>
                  <Box>
                    <Box className={classes.header}>
                      <Typography
                        variant="h6"
                        className={classes.title}
                        sx={{
                          color: "#2499EF",
                          textShadow: "2px 4px 3px lightblue",
                          display: "flex",
                        }}
                      >
                        <Skeleton animation="wave" width={"140px"} />
                      </Typography>

                      <span className={classes.icon2}>
                        <Skeleton
                          animation="wave"
                          variant="circular"
                          width={"62px"}
                          height={"60px"}
                        />
                      </span>
                    </Box>
                    <Box
                      sx={{
                        marginBlockStart: "-45px",
                        marginInlineStart: "5px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          padding: "10px",
                          fontSize: "20px",
                          width: "140px",
                        }}
                      >
                        <Skeleton animation="wave" />
                      </Typography>
                    </Box>
                    <Box className={classes.box}>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          <Skeleton animation="wave" />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: "5px" }}
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          <Skeleton animation="wave" />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: "6px" }}
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          <Skeleton animation="wave" />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: "6px" }}
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                lg={3}
                md={6}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Card className={classes.card}>
                  <Box>
                    <Box className={classes.header}>
                      <Typography
                        variant="h6"
                        className={classes.title}
                        sx={{
                          color: "#2499EF",
                          textShadow: "2px 4px 3px lightblue",
                          display: "flex",
                        }}
                      >
                        <Skeleton animation="wave" width={"140px"} />
                      </Typography>

                      <span className={classes.icon3}>
                        <Skeleton
                          animation="wave"
                          variant="circular"
                          width={"62px"}
                          height={"60px"}
                        />
                      </span>
                    </Box>
                    <Box
                      sx={{
                        marginBlockStart: "-45px",
                        marginInlineStart: "5px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          padding: "10px",
                          fontSize: "20px",
                          width: "140px",
                        }}
                      >
                        <Skeleton animation="wave" />
                      </Typography>
                    </Box>
                    <Box className={classes.box}>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          <Skeleton animation="wave" />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: "5px" }}
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          <Skeleton animation="wave" />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: "6px" }}
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          <Skeleton animation="wave" />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: "6px" }}
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CardBox;
