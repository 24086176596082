import React from "react";
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import "../../CommonCss/Button.css";
import NodataFound from "../../components/Layouts/NodataFound";
import UserTable from "../../components/table/CustomTable";
import ScrollBar from "simplebar-react";
import {
  capitalizeFirstLetter,
  convertToUTCString,
  isArrayEmpty,
  stringAvatar,
  timeDifference,
} from "../../utils/common.service";
import { BootstrapDialogTitle } from "../../components/Layouts/Modal/CustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import "../../CommonCss/Button.css";
import { H3, Small } from "../../components/Typography";
import moment from "moment";
import { CONSTANT } from "../../utils/constant";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import FlexBox from "../../components/FlexBox";

const UserActivity = (props) => {
  const { closeModal, data, backdropOpen } = props;
  const UserListColumnShape = [
    {
      Header: "SessionId",
      accessor: "_id",
      minWidth: 70,
      Cell: ({ value }) => (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <Small color="text.primary" className="ellipse-id">
              <Tooltip title={value || "-"} placement="top-start">
                {value || "-"}
              </Tooltip>
            </Small>
          </FlexBox>
        </FlexBox>
      ),
    },
    {
      Header: "Location",
      accessor: "location",
      minWidth: 120,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },
    {
      Header: "Country",
      accessor: "country",
      minWidth: 50,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },
    {
      Header: "Timezone",
      accessor: "timezone",
      minWidth: 60,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },
    {
      Header: "Start Time",
      accessor: "starttime",
      minWidth: 110,
      Cell: ({ value }) => {
        let time = convertToUTCString(value);
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {moment(time).format(CONSTANT.DATE_FORMATE)}
            </Small>{" "}
            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {moment(time).format("LT")}
            </Grid>
          </>
        );
      },
    },
    {
      Header: "End Time",
      accessor: "endtime",
      minWidth: 110,
      Cell: ({ value }) => {
        let time = convertToUTCString(value);

        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {moment(time).format(CONSTANT.DATE_FORMATE)}
            </Small>{" "}
            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {moment(time).format("LT")}
            </Grid>
          </>
        );
      },
    },
    {
      Header: "Total Spent",
      accessor: "totalTime",
      minWidth: 110,
      Cell: ({ row }) => {
        const starttime = convertToUTCString(row?.original?.starttime);
        const endtime = convertToUTCString(row?.original?.endtime);
        const { minutes, seconds } = timeDifference(starttime, endtime);
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {minutes} min
            </Small>{" "}
            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {seconds} sec
            </Grid>
          </>
        );
      },
    },
    {
      Header: "Created Date",
      accessor: "createdAt",
      minWidth: 130,
      Cell: ({ value }) => {
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {moment(value).format(CONSTANT.DATE_FORMATE)}
            </Small>{" "}
            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {moment(value).format("LT")}
            </Grid>
          </>
        );
      },
    },

    {
      Header: "Updated Date",
      accessor: "updatedAt",
      minWidth: 130,
      Cell: ({ value }) => {
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {moment(value).format(CONSTANT.DATE_FORMATE)}
            </Small>

            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {moment(value).format("LT")}
            </Grid>
          </>
        );
      },
    },
  ];
  return (
    <Grid id="history">
      <BootstrapDialogTitle
        sx={{
          position: "sticky",
          top: "-10px",
          zIndex: "999",
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "space-between",
          ml: "-8px",
          mb: "10px",
          pb: "0",
          pt: "0",
        }}
      >
        <h3>User Activity</h3>

        <IconButton>
          <CloseIcon onClick={closeModal} />
        </IconButton>
      </BootstrapDialogTitle>
      <Grid
        container
        sx={{
          paddingX: "18px",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "3px",
            mb: "5px",
            float: "right",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            UserId:
          </Typography>

          <Small
            sx={{
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            {data[0]?.user_id}
          </Small>
        </Grid>
      </Grid>
      <Grid
        sx={{
          paddingX: "18px",
          height: "340px",
          overflow: "scroll",
        }}
      >
        <UserTable
          columnShape={UserListColumnShape}
          data={data}
          hidePagination={false}
        />
        {!data || (data.length <= 0 && <NodataFound />)}
      </Grid>
      <Grid
        container
        sx={{
          paddingX: "18px",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            mt: "5px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            Total Record:
          </Typography>

          <Small
            sx={{
              borderRadius: 10,
              padding: "6px 30px 6px 30px",
              color: "white",
              backgroundColor: "#44564a",
            }}
          >
            {data?.length || 0}
          </Small>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserActivity;
