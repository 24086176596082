import React from "react";
import {
  Avatar,
  Badge,
  Box,
  ButtonBase,
  Divider,
  Grid,
  Tooltip,
  styled,
} from "@mui/material";
import FlexBox from "../../../components/FlexBox";
import { H6, Small, Tiny } from "../../../components/Typography";
import UkoAvatar from "../../../components/UkoAvatar";
import useAuth from "../../../hooks/useAuth";
import { Fragment, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PopoverLayout from "./PopoverLayout";
import jwtDecode from "jwt-decode";
import { stringAvatar } from "../../../utils/common.service";

// styled components
const StyledSmall = styled(Small)(({ theme }) => ({
  display: "block",
  padding: "5px 1rem",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.secondary.light
        : theme.palette.divider,
  },
}));

const ProfilePopover = () => {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const [open, setOpen] = useState(false);

  const token = localStorage.getItem(btoa("token"));
  const decode = token ? jwtDecode(token) : {};

  const handleMenuItem = (path) => {
    navigate(path);
    setOpen(false);
  };

  return (
    <Fragment>
      <ButtonBase disableRipple ref={anchorRef} onClick={() => setOpen(true)}>
        <Badge
          overlap="circular"
          sx={{
            "& .MuiBadge-badge": {
              width: 11,
              height: 11,
              right: "7%",
              borderRadius: "50%",
              border: "2px solid #fff",
              backgroundColor: "success.main",
            },
            mt: 1,
          }}
        >
          <Grid>
            <Tooltip
              title={
                decode?.username?.charAt(0).toUpperCase() +
                decode?.username?.slice(1)
              }
            >
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                  ml: 1,
                }}
                {...stringAvatar(decode?.username?.toUpperCase() || "")}
              />
            </Tooltip>
          </Grid>
        </Badge>
      </ButtonBase>{" "}
      <PopoverLayout
        hiddenViewButton
        maxWidth={230}
        minWidth={200}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        title={
          <FlexBox alignItems="center">
            <Grid sx={{ marginTop: "9px" }}>
              <Avatar
                sx={{ width: 35, height: 35 }}
                {...stringAvatar(decode?.username?.toUpperCase() || "")}
              />
            </Grid>

            <Grid ml={1}>
              <H6>
                {decode?.username?.charAt(0).toUpperCase() +
                  decode?.username?.slice(1)}
              </H6>
            </Grid>
          </FlexBox>
        }
      >
        <Box pt={1}>
          {/* <StyledSmall
            onClick={() => handleMenuItem("/dashboard/user-profile")}
          >
            Set Status
          </StyledSmall> */}

          <StyledSmall
            onClick={() => handleMenuItem("/dashboard/user-profile")}
          >
            Profile & Account
          </StyledSmall>

          <Divider sx={{ my: 1 }} />

          <StyledSmall
            onClick={() => {
              logout();
              navigate("/login");
              toast.success("You Logout Successfully");
            }}
          >
            Sign Out
          </StyledSmall>
        </Box>
      </PopoverLayout>
    </Fragment>
  );
};

export default ProfilePopover;
