import React from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Popover,
  useTheme,
} from "@mui/material";
import { H2, H5, H6, Small } from "../../../components/Typography";
import Chart from "react-apexcharts";
import {
  formatCount,
  formatCountString,
  sentenceCase,
} from "../../../utils/common.service";
import { CONSTANT } from "../../../utils/constant";
import { KeyboardArrowDown } from "@mui/icons-material";

const TotalSpent = (props) => {
  const {
    handleChange,
    selected,
    transactionAmount,
    setOpen,
    anchorRef,
    open,
  } = props;

  const data = {
    series: [
      {
        name: "Total",
        data: transactionAmount,
      },
    ],
    categories:
      selected === CONSTANT.SELECTED_MENU[0]
        ? CONSTANT.WEEKLY
        : selected === CONSTANT.SELECTED_MENU[1]
        ? CONSTANT.MONTHLY
        : CONSTANT.YEARLY,
  };

  const theme = useTheme();

  const chartOptions = {
    chart: {
      background: "transparent",
      toolbar: { show: false },
    },
    colors: "rgb(255, 225, 53)",
    dataLabels: { enabled: false },
    fill: { opacity: 1 },
    grid: {
      show: false,
    },
    states: {
      active: {
        filter: { type: "none" },
      },
      hover: {
        filter: { type: "none" },
      },
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: { show: true },
      axisTicks: { show: false },
      categories: data.categories,
      labels: {
        style: {
          colors: "black",
          fontFamily: theme.typography.fontFamily,
          fontWeight: 500,
        },
      },
    },
    yaxis: { show: false },

    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: "60%",
        rangeBarOverlap: true,
      },
    },
    tooltip: {
      x: { show: true },
      y: {
        formatter: (val) => `$${val}`,
      },
    },

    responsive: [
      {
        breakpoint: 550,
        options: {
          chart: {
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          xaxis: {
            labels: { show: false },
          },
          yaxis: {
            show: true,
            labels: {
              style: {
                colors: theme.palette.text.disabled,
                fontFamily: theme.typography.fontFamily,
                fontWeight: 500,
              },
            },
          },
        },
      },
    ],
  };

  const chartSeries = data.series;

  return (
    <Card
      sx={{
        paddingX: 4,
        height: "100%",
        paddingBottom: "1.5rem",
        paddingTop: "calc(1.5rem + 15px)",
        [theme.breakpoints.down(425)]: { padding: "1.5rem" },
      }}
    >
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <H5>
          {selected === CONSTANT.SELECTED_MENU[0]
            ? CONSTANT.MENU_FORMAT[0]
            : selected === CONSTANT.SELECTED_MENU[1]
            ? CONSTANT.MENU_FORMAT[1]
            : CONSTANT.MENU_FORMAT[2]}{" "}
          Transaction
        </H5>
        <Button
          disableRipple
          onClick={() => setOpen(true)}
          ref={anchorRef}
          endIcon={<KeyboardArrowDown sx={{ color: "text.disabled" }} />}
          sx={{ p: 0, "&:hover": { backgroundColor: "transparent" } }}
        >
          <H6 color="text.disabled">
            {selected === CONSTANT.SELECTED_MENU[0]
              ? CONSTANT.MENU_FORMAT[0]
              : selected === CONSTANT.SELECTED_MENU[1]
              ? CONSTANT.MENU_FORMAT[1]
              : CONSTANT.MENU_FORMAT[2]}
          </H6>
        </Button>
        <Popover
          keepMounted
          open={open}
          onClose={() => setOpen(false)}
          anchorEl={anchorRef.current}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          PaperProps={{ sx: { py: "0.5rem" } }}
        >
          {CONSTANT.SELECTED_MENU.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => handleChange(item)}
              sx={{
                "&:hover": {
                  color: "rgb(255, 225, 53)",
                  backgroundColor: "black",
                },
              }}
            >
              <Small fontWeight={500} py={0.5}>
                {item === CONSTANT.SELECTED_MENU[0]
                  ? CONSTANT.MENU_FORMAT[0]
                  : item === CONSTANT.SELECTED_MENU[1]
                  ? CONSTANT.MENU_FORMAT[1]
                  : CONSTANT.MENU_FORMAT[2]}
              </Small>
            </MenuItem>
          ))}
        </Popover>
      </Grid>

      <H2 color={theme.palette.text.disabled}>
        $ {formatCount(transactionAmount.reduce((acc, curr) => acc + curr, 0))}
        {formatCountString(
          transactionAmount.reduce((acc, curr) => acc + curr, 0)
        )}
      </H2>

      <Box
        sx={{
          "& .apexcharts-tooltip *": {
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
          },
          "& .apexcharts-tooltip": {
            boxShadow: 0,
            borderRadius: 4,
            alignItems: "center",
            "& .apexcharts-tooltip-text-y-value": { color: "black" },
            "& .apexcharts-tooltip.apexcharts-theme-light": {
              border: `1px solid ${theme.palette.divider}`,
            },
            "& .apexcharts-tooltip-series-group:last-child": {
              paddingBottom: 0,
            },
          },
        }}
      >
        <Chart
          height={245}
          options={chartOptions}
          series={chartSeries}
          type="bar"
        />
      </Box>
    </Card>
  );
};

export default TotalSpent;
