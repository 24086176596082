import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { FilterAlt } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { CONSTANT } from "../../utils/constant";
import DatePicker from "react-datepicker";
import { sentenceCase } from "../../utils/common.service";
import { FaFileCsv, FaFilePdf } from "react-icons/fa";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Small } from "../Typography";
import {
  TbSortAscendingLetters,
  TbSortDescendingLetters,
} from "react-icons/tb";
import "./filterMenu.css";

const FilterMenu = (props) => {
  const {
    pageObj,
    setPageObj,
    statusList,
    exportModalHandler,
    showSort,
    showDatePicker,
    showService,
    showStatus,
    showRangePicker,
    showExport,
    startDate,
    endDate,
    setDateRange,
    onDateChange,
    setNotificationStartDate,
    notificationStartDate,
    totalRecord,
    serviceList,
  } = props;
  const [filterEl, setFilterEl] = React.useState(null);
  const [exportEl, setExportEl] = React.useState(null);
  const open = Boolean(filterEl);
  const openExpo = Boolean(exportEl);

  const handleClose = (order = pageObj.sortingOrder) => {
    setPageObj({ ...pageObj, sortingOrder: order || "" });
    setFilterEl(null);
  };

  const PaperProps = {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "self-end" }}>
        {showDatePicker && (
          <Grid sx={{ ml: 3, mt: "3px" }} className="menu">
            <DatePicker
              className="date-picker"
              isClearable
              placeholderText="MM/DD/YYYY"
              selected={notificationStartDate || null}
              onChange={(update) => {
                setNotificationStartDate(update);
                onDateChange();
              }}
            />
          </Grid>
        )}

        {showService && (
          <FormControl sx={{ minWidth: 140, ml: 3 }} className="menu">
            <InputLabel
              id="demo-simple-select-autowidth-label"
              sx={{ color: "#1d2438" }}
            >
              Service type
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={pageObj.service || ""}
              onChange={(e) =>
                setPageObj({ ...pageObj, service: e.target.value })
              }
              autoWidth
              placeholder="select service"
              label="Service type"
              sx={{ height: 40, mt: "5px" }}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {serviceList?.map((a, i) => (
                <MenuItem key={i} value={a.key}>
                  {a.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {showStatus && (
          <FormControl sx={{ minWidth: 140, ml: 3 }} className="menu">
            <InputLabel
              id="demo-simple-select-autowidth-label"
              sx={{ color: "#1d2438" }}
            >
              Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={pageObj.status || ""}
              onChange={(e) =>
                setPageObj({ ...pageObj, status: e.target.value })
              }
              autoWidth
              placeholder="select status"
              label="Status"
              sx={{ height: 40, mt: "5px" }}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {statusList?.map((a, i) => (
                <MenuItem key={i} value={a.key}>
                  {a.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {showRangePicker && (
          <Grid sx={{ mt: "3px", ml: 3.1 }} className="menu">
            <DatePicker
              className="date-picker"
              selectsRange={true}
              startDate={startDate || null}
              endDate={endDate || null}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable={true}
              placeholderText="MM/DD/YYYY"
            />
          </Grid>
        )}

        {showExport && (
          <Tooltip className="menu">
            <Grid
              sx={{ ml: 3 }}
              className="export-button"
              onClick={(event) => setExportEl(event.currentTarget)}
            >
              <Small sx={{ fontWeight: 600, color: "#707070" }}>Export</Small>
              <ExitToAppIcon sx={{ color: "#707070" }} />
            </Grid>
          </Tooltip>
        )}

        {showSort && (
          <Tooltip title="Sort" placement="top-start" className="menu">
            <IconButton
              onClick={(event) => setFilterEl(event.currentTarget)}
              size="small"
              sx={{ ml: 3 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <FilterAlt sx={{ width: 32, height: 32 }} />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Menu
        anchorEl={filterEl}
        id="account-menu"
        open={open}
        onClose={() => setFilterEl(null)}
        PaperProps={{ ...PaperProps }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => handleClose(CONSTANT.ASC)}
          className={`sort-order ${
            pageObj.sortingOrder === CONSTANT.ASC ? CONSTANT.ACTIVE : ""
          }`}
        >
          <Tooltip
            placement="right-start"
            title={sentenceCase(CONSTANT.ASCENDING)}
          >
            <TbSortAscendingLetters className="asc-des" />
          </Tooltip>
        </MenuItem>
        <MenuItem
          onClick={() => handleClose(CONSTANT.DESC)}
          className={`sort-order ${
            pageObj.sortingOrder === CONSTANT.DESC ? CONSTANT.ACTIVE : ""
          }`}
        >
          <Tooltip
            placement="right-start"
            title={sentenceCase(CONSTANT.DESCENDING)}
          >
            <TbSortDescendingLetters className="asc-des" />
          </Tooltip>
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={exportEl}
        id="export-option"
        open={openExpo}
        onClose={() => setExportEl(null)}
        PaperProps={{ ...PaperProps }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Tooltip title={CONSTANT.EXP_CSV} placement="right-start">
          <MenuItem onClick={() => exportModalHandler(CONSTANT.CSV)}>
            <FaFileCsv size="28px" />
          </MenuItem>
        </Tooltip>
        <Tooltip title={CONSTANT.EXP_PDF} placement="right-start">
          <MenuItem onClick={() => exportModalHandler(CONSTANT.PDF)}>
            <FaFilePdf size="28px" />
          </MenuItem>
        </Tooltip>
      </Menu>
    </React.Fragment>
  );
};

export default FilterMenu;
