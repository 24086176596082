import React, { useEffect, useState, useContext } from "react";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import FlexBox from "../../components/FlexBox";
import SearchInput from "../../components/SearchInput";
import useTitle from "../../hooks/useTitle";
import "../../CommonCss/Button.css";
import userService from "../../service/user.service";
import {
  StyledPagination,
  capitalizeFirstLetter,
  notifyError,
  notifySuccess,
  returnError,
  showConfirmDialog,
  stringAvatar,
} from "../../utils/common.service";
import { CONSTANT, MESSAGE, PAGE_CONSTANT } from "../../utils/constant";
import { Small } from "../../components/Typography";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Stack } from "@mui/system";
import "./Ingredient.css";
import NodataFound from "../../components/Layouts/NodataFound";
import UserTable from "../../components/table/CustomTable";
import { BackdropContext } from "../../contexts/BackdropContext";
import FilterMenu from "../../components/filter/filterMenu";
import moment from "moment";
import CustomizationDialog from "../../components/Layouts/Modal/CustomizationModal";
import CustomizationUserFormDialog from "../../components/Layouts/Modal/CustomizationUserFormModal";
import IngredientDetailsForm from "./IngredientDetailsForm";
import IngredientForm from "./IngredientForm";

const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const Ingredient = () => {
  useTitle("Ingredient");

  const [role, setRole] = useState("");
  const { backdropOpen, setBackdropOpen } = useContext(BackdropContext);

  const [pageObj, setPageObj] = useState({
    pageCount: 1,
    limit: 5,
    search: "",
    sortingField: "",
    sortingOrder: "desc",
  });

  const [ingredientData, setIngredientData] = useState([]);
  const [totalRecord, setTotalRecord] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState([]);
  const [userId, setUserId] = useState("");
  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [displayData, setDisplayData] = useState({});

  useEffect(() => {
    document.title = PAGE_CONSTANT.USER_LIST;
  }, []);

  useEffect(() => {
    fetchData({ ...pageObj });
  }, [pageObj]);

  const UserListColumnShape = [
    {
      Header: "Title",
      accessor: "title",
      minWidth: 150,
      Cell: ({ value }) => {
        return (
          <Small
            sx={{
              color: "text.primary",
            }}
          >
            {value || "-"}
          </Small>
        );
      },
    },

    {
      Header: "Description",
      accessor: "description",
      minWidth: 200,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Count Down",
      accessor: "countdown",
      minWidth: 70,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Health Status",
      accessor: "health_status",
      minWidth: 70,
      Cell: ({ value }) => {
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                padding: ".2rem 1rem",
                color: "background.paper",
                backgroundColor: value === "healthy" ? "#0fb70f" : "#ff6262",
              }}
            >
              {capitalizeFirstLetter(value) || "-"}
            </Small>
          </>
        );
      },
    },

    {
      Header: "Search Count",
      accessor: "searchCount",
      minWidth: 70,
      Cell: ({ value }) => {
        return (
          <>
            <Small
              sx={{
                color: "text.primary",
              }}
            >
              {value || "-"}
            </Small>{" "}
          </>
        );
      },
    },

    {
      Header: "Action",
      accessor: "action",
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row }) => {
        const { _id } = row.original;
        return (
          <FlexBox sx={{ display: "flex", justifyContent: "right" }}>
            <Tooltip
              title="Ingredients Detail"
              placement="top-start"
              sx={{
                cursor: "pointer",
                border: "1px solid",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
                padding: "2px",
                mr: "10px",
              }}
              onClick={(e) => {
                handleOpenUserDetailsModal(row);
              }}
            >
              <RemoveRedEyeIcon className="icons-details" color="black" />
            </Tooltip>

            <Tooltip
              title="Edit"
              placement="top-start"
              sx={{
                cursor: "pointer",
                border: "1px solid",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
              }}
              onClick={(e) => {
                handleOpenModal(_id);
              }}
            >
              <DriveFileRenameOutlineRoundedIcon
                className="icons-edit"
                color="primary"
              />
            </Tooltip>

            <Tooltip
              title="Delete"
              placement="top-start"
              sx={{
                cursor: "pointer",
                marginLeft: "10px",
                border: "1px solid",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
                padding: "2px",
              }}
              onClick={(e) => {
                handleDeleteIngredient(_id);
              }}
            >
              <DeleteOutlineOutlinedIcon
                className="icons-delete"
                color="error"
              />
            </Tooltip>
          </FlexBox>
        );
      },
    },
  ];

  const fetchData = async (obj) => {
    try {
      setBackdropOpen(true);
      let data = {
        ...obj,
        pageCount: obj.pageCount - 1,
      };

      userService
        .getAllIngredient({ ...data })
        .then((res) => {
          if (res.status === 200) {
            setBackdropOpen(false);
            setIngredientData(res?.ingredient || []);
            setTotalRecord(res?.totalIngredients || 0);
            notifySuccess(res.message);
          } else {
            setTotalRecord(res?.totalIngredients || 0);
            setIngredientData([]);
            setBackdropOpen(false);
            notifyError(res.message);
          }
        })
        .catch((err) => {
          setBackdropOpen(false);
          notifyError(returnError(err));
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(returnError(err));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(event.target.value);
    }
  };

  const handleSearch = (value) => {
    setPageObj({ ...pageObj, search: value, pageCount: 1 });
  };

  const handleChange = (_event, currentPage) => {
    setPageObj({ ...pageObj, pageCount: currentPage });
  };

  const handleRowsChange = (event, currentPage) => {
    setPageObj({
      ...pageObj,
      pageCount: 1,
      limit: parseInt(event.target.value),
    });
  };

  const fetchUserById = (id) => {
    try {
      setBackdropOpen(true);
      userService
        .getIngredientById(id)
        .then((res) => {
          if (res.status === 200) {
            setBackdropOpen(false);
            setEditData(res);
            setOpenModal(true);
          } else {
            setBackdropOpen(false);
          }
        })
        .catch((err) => {
          notifyError(err ? err?.response?.data?.message : err?.message);
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(err ? err?.response?.data?.message : err?.message);
    }
  };

  const handleDeleteIngredient = async (_id) => {
    showConfirmDialog(CONSTANT.DELETE_CONFIRMATION, async (confirmed) => {
      if (confirmed) {
        const editData = await userService.deleteIngredientById(_id);
        if (editData && editData.status === 200) {
          notifySuccess(editData.message);
          setPageObj({
            ...pageObj,
            refresh: Date.now(),
          });
        } else {
          notifyError(editData.message || MESSAGE.NETWORK_ERROR);
        }
      }
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      pageObj["sortingField"] === property && pageObj["sortingOrder"] === "asc";
    const order = isAsc ? "desc" : "asc";
    setPageObj({
      ...pageObj,
      sortingField: property,
      sortingOrder: order,
    });
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleOpenModal = async (_id) => {
    if (_id) {
      setUserId(_id);
      fetchUserById(_id);
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setUserId("");
    setOpenModal(false);
  };

  const handleOpenUserDetailsModal = (row) => {
    setOpenUserDetailsModal(true);
    setDisplayData({ ...row });
  };

  const handleCloseUserDetailsModal = () => {
    setOpenUserDetailsModal(false);
  };

  return (
    <Box pt={2} pb={4}>
      <Grid
        sx={{ display: "flex", justifyContent: "end" }}
        className="style-box"
      ></Grid>

      <StyledFlexBox sx={{ mt: 2 }} className="style-box">
        <SearchInput
          sx={{ width: "inherit" }}
          placeholder="Search users..."
          onKeyPress={handleKeyPress}
        />
        <FilterMenu
          setPageObj={setPageObj}
          pageObj={pageObj}
          showSort={false}
          showStatus={false}
          showRangePicker={false}
          showExport={false}
        />
      </StyledFlexBox>

      <Grid>
        <UserTable
          isSort={true}
          pageObj={pageObj}
          columnShape={UserListColumnShape}
          data={ingredientData}
          hidePagination={false}
          createSortHandler={createSortHandler}
        />
        {!backdropOpen &&
          (!ingredientData || (ingredientData.length <= 0 && <NodataFound />))}
      </Grid>

      {backdropOpen && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            Total Record:
          </Typography>

          <Small
            sx={{
              borderRadius: 10,
              padding: "6px 30px 6px 30px",
              color: "white",
              backgroundColor: "#44564a",
            }}
          >
            {totalRecord || 0}
          </Small>
        </Grid>

        {ingredientData?.length > 0 && (
          <Stack marginY={1}>
            <StyledPagination
              count={
                (totalRecord && Math.ceil(totalRecord / pageObj.limit)) || 0
              }
              shape="rounded"
              page={pageObj?.pageCount || 0}
              onChange={handleChange}
              onRowsPerPageChange={handleRowsChange}
            />
          </Stack>
        )}
      </Grid>

      <CustomizationUserFormDialog
        title="Staff Information"
        open={openUserDetailsModal}
        handleClose={handleCloseUserDetailsModal}
        children={
          <IngredientDetailsForm
            closeModal={handleCloseUserDetailsModal}
            data={displayData}
          />
        }
      />

      <CustomizationDialog
        open={openModal}
        handleClose={handleCloseModal}
        children={
          <IngredientForm
            id={userId}
            closeModal={handleCloseModal}
            editData={editData}
            pageObj={pageObj}
            setPageObj={setPageObj}
          />
        }
      />
    </Box>
  );
};

export default Ingredient;
