import React from "react";
import AuthGuard from "./components/authentication/AuthGuard";
import GuestGuard from "./components/authentication/GuestGuard";
import DashboardLayout from "./components/Layouts/DashboardLayout";
import { Navigate } from "react-router-dom";
import Login from "./pages/authentication/Login";
import Register from "./pages/authentication/Register";
import UserVerification from "./pages/authentication/UserVerification";
import PasswordVerification from "./pages/authentication/PasswordVerification";
import ForgetPassword from "./pages/authentication/ForgetPassword";
import ResetPassword from "./pages/authentication/ResetPassword";
import DashboardSaaS from "./pages/dashboards/SaaS";
import Error from "./pages/404";
import UsersList from "./pages/users/Users";
import Ingredient from "./pages/ingredient/Ingredient";
import Support from "./pages/support/Support";
import SupportThread from "./pages/support/SupportThred";
import History from "./pages/history/History";
import FeedbackList from "./components/FeedBack/FeedBackList";
import Activity from "./pages/Activity/Activity";

const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "register",
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    ),
  },
  {
    path: "password-verification",
    element: (
      <GuestGuard>
        <PasswordVerification />
      </GuestGuard>
    ),
  },
  {
    path: "user-verification",
    element: (
      <GuestGuard>
        <UserVerification />
      </GuestGuard>
    ),
  },
  {
    path: "forget-password",
    element: (
      <GuestGuard>
        <ForgetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "reset-password",
    element: (
      <GuestGuard>
        <ResetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashboardSaaS />,
      },
      {
        path: "users",
        element: <UsersList />,
      },
      {
        path: "ingredients",
        element: <Ingredient />,
      },
      {
        path: "support",
        element: <Support />,
      },
      {
        path: "support-thread",
        element: <SupportThread />,
      },
      {
        path: "history",
        element: <History />,
      },
      {
        path: "activity",
        element: <Activity />,
      },
      {
        path: "feedback",
        element: <FeedbackList />,
      },
    ],
  },

  {
    path: "*",
    element: <Error />,
  },
];

export default routes;
