import React, { useEffect, useState, useContext } from "react";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import FlexBox from "../../components/FlexBox";
import SearchInput from "../../components/SearchInput";
import useTitle from "../../hooks/useTitle";
import "../../CommonCss/Button.css";
import userService from "../../service/user.service";
import {
  StyledPagination,
  capitalizeFirstLetter,
  notifyError,
  notifySuccess,
  returnError,
  showConfirmDialog,
  stringAvatar,
} from "../../utils/common.service";
import { CONSTANT, MESSAGE, PAGE_CONSTANT } from "../../utils/constant";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

import { Small } from "../../components/Typography";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Stack } from "@mui/system";
import "./UserList.css";
import NodataFound from "../../components/Layouts/NodataFound";
import UserTable from "../../components/table/CustomTable";
import { BackdropContext } from "../../contexts/BackdropContext";
import FilterMenu from "../../components/filter/filterMenu";
import moment from "moment";
import CustomizationDialog from "../../components/Layouts/Modal/CustomizationModal";
import UserForm from "./UserForm";
import HistoryList from "./HistoryList";
import UserActivity from "./UserActivity";

const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": { maxWidth: "100%" },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const UserList = () => {
  useTitle("Users");

  const [role, setRole] = useState("");
  const { backdropOpen, setBackdropOpen } = useContext(BackdropContext);

  const [pageObj, setPageObj] = useState({
    pageCount: 1,
    limit: 5,
    search: "",
    sortingField: "",
    sortingOrder: "desc",
  });

  const [usersData, setUsersData] = useState([]);
  const [totalRecord, setTotalRecord] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [editData, setEditData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    document.title = PAGE_CONSTANT.USER_LIST;
  }, []);

  useEffect(() => {
    fetchData({ ...pageObj });
  }, [pageObj]);

  const UserListColumnShape = [
    {
      Header: "Name",
      accessor: "username",
      minWidth: 200,
      Cell: ({ value }) => {
        return (
          <FlexBox alignItems="center">
            <Avatar
              {...stringAvatar(value.toUpperCase() || "")}
              sx={{
                mb: 0,
                background: "#44564a",
                width: "30px",
                height: "30px",
                fontSize: "15px",
              }}
            />

            <FlexBox flexDirection="column" ml={1}>
              <Small color="text.primary">{capitalizeFirstLetter(value)}</Small>
            </FlexBox>
          </FlexBox>
        );
      },
    },

    {
      Header: "Email",
      accessor: "email",
      minWidth: 150,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Refer Code",
      accessor: "referCode",
      minWidth: 150,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Remained Credit",
      accessor: "remainedCredit",
      minWidth: 200,
      Cell: ({ value }) => (
        <Small
          sx={{
            color: "text.primary",
          }}
        >
          {value || "-"}
        </Small>
      ),
    },

    {
      Header: "Created Date",
      accessor: "createdAt",
      minWidth: 140,
      Cell: ({ value }) => {
        return (
          <>
            <Small
              sx={{
                borderRadius: 10,
                color: "text.primary",
              }}
            >
              {moment(value).format(CONSTANT.DATE_FORMATE)}
            </Small>{" "}
            <Grid
              sx={{
                borderRadius: 10,
                color: "gray",
                mt: "2px",
              }}
            >
              {moment(value, "HH:mm").format("hh:mm A")}
            </Grid>
          </>
        );
      },
    },

    {
      Header: "Action",
      accessor: "action",
      minWidth: 100,
      maxWidth: 100,
      Cell: ({ row }) => {
        const { _id } = row.original;
        return (
          <FlexBox sx={{ display: "flex", justifyContent: "right" }}>
            <Tooltip
              title="Edit"
              placement="top-start"
              sx={{
                cursor: "pointer",
                border: "1px solid",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
              }}
              onClick={(e) => {
                handleOpenModal(_id);
              }}
            >
              <DriveFileRenameOutlineRoundedIcon
                className="icons-edit"
                color="primary"
              />
            </Tooltip>
            <Tooltip
              title="History"
              placement="top-start"
              sx={{
                cursor: "pointer",
                border: "1px solid",
                marginLeft: "10px",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
              }}
              onClick={(e) => {
                handleOpenHistoryModal(_id);
              }}
            >
              <HistoryOutlinedIcon className="icons-edit" color="primary" />
            </Tooltip>
            <Tooltip
              title="Activity"
              placement="top-start"
              sx={{
                cursor: "pointer",
                border: "1px solid",
                marginLeft: "10px",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
              }}
              onClick={(e) => {
                handleOpenActivityModal(_id);
              }}
            >
              <AccessTimeOutlinedIcon className="icons-edit" color="primary" />
            </Tooltip>
            <Tooltip
              title="Delete"
              placement="top-start"
              sx={{
                cursor: "pointer",
                marginLeft: "10px",
                border: "1px solid",
                borderRadius: "5px",
                height: "30px",
                width: "30px",
                padding: "2px",
              }}
              onClick={(e) => {
                handleDeleteUser(_id);
              }}
            >
              <DeleteOutlineOutlinedIcon
                className="icons-delete"
                color="error"
              />
            </Tooltip>
          </FlexBox>
        );
      },
    },
  ];

  const fetchData = async (obj) => {
    try {
      setBackdropOpen(true);
      let data = {
        ...obj,
        pageCount: obj.pageCount - 1,
      };

      userService
        .getAllUsers({ ...data })
        .then((res) => {
          if (res.status === 200) {
            setBackdropOpen(false);
            setUsersData(res?.data || []);
            setTotalRecord(res?.totalRecords || 0);
            notifySuccess(res.message);
          } else {
            setTotalRecord(res?.totalRecords || 0);
            setUsersData([]);
            setBackdropOpen(false);
            notifyError(res.message);
          }
        })
        .catch((err) => {
          setBackdropOpen(false);
          notifyError(returnError(err));
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(returnError(err));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(event.target.value);
    }
  };

  const handleSearch = (value) => {
    setPageObj({ ...pageObj, search: value, pageCount: 1 });
  };

  const handleChange = (_event, currentPage) => {
    setPageObj({ ...pageObj, pageCount: currentPage });
  };

  const handleRowsChange = (event, currentPage) => {
    setPageObj({
      ...pageObj,
      pageCount: 1,
      limit: parseInt(event.target.value),
    });
  };

  const fetchUserById = (id, flag) => {
    try {
      setBackdropOpen(true);
      userService
        .getUserById(id)
        .then((res) => {
          if (res.status === 200) {
            setBackdropOpen(false);
            setEditData(res);
            flag ? setOpenHistoryModal(true) : setOpenModal(true);
          } else {
            setBackdropOpen(false);
          }
        })
        .catch((err) => {
          notifyError(err ? err?.response?.data?.message : err?.message);
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(err ? err?.response?.data?.message : err?.message);
    }
  };

  const fetchUserActivityById = (id) => {
    try {
      setBackdropOpen(true);
      userService
        .getUserActivityById(id)
        .then((res) => {
          if (res.status === 200) {
            setBackdropOpen(false);
            setActivityData(res?.sessions);
            setOpenActivityModal(true);
          } else {
            setBackdropOpen(false);
          }
        })
        .catch((err) => {
          notifyError(err ? err?.response?.data?.message : err?.message);
        });
    } catch (err) {
      setBackdropOpen(false);
      notifyError(err ? err?.response?.data?.message : err?.message);
    }
  };

  const handleDeleteUser = async (_id) => {
    showConfirmDialog(CONSTANT.DELETE_CONFIRMATION, async (confirmed) => {
      if (confirmed) {
        const editData = await userService.deleteUser(_id);
        if (editData && editData.status === 200) {
          notifySuccess(editData.message);
          setPageObj({
            ...pageObj,
            refresh: Date.now(),
          });
        } else {
          notifyError(editData.message || MESSAGE.NETWORK_ERROR);
        }
      }
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      pageObj["sortingField"] === property && pageObj["sortingOrder"] === "asc";
    const order = isAsc ? "desc" : "asc";
    setPageObj({
      ...pageObj,
      sortingField: property,
      sortingOrder: order,
    });
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleOpenModal = async (_id) => {
    if (_id) {
      setUserId(_id);
      fetchUserById(_id);
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setUserId("");
    setOpenModal(false);
  };

  const handleOpenHistoryModal = async (_id) => {
    if (_id) {
      fetchUserById(_id, "history");
    } else {
      setOpenHistoryModal(true);
    }
  };

  const handleCloseHistoryModal = () => {
    setUserId("");
    setOpenHistoryModal(false);
  };

  const handleOpenActivityModal = async (_id) => {
    if (_id) {
      fetchUserActivityById(_id, "history");
    } else {
      setOpenActivityModal(true);
    }
  };

  const handleCloseActivityModal = () => {
    setUserId("");
    setOpenActivityModal(false);
  };

  return (
    <Box pt={2} pb={4}>
      <Grid
        sx={{ display: "flex", justifyContent: "end" }}
        className="style-box"
      ></Grid>

      <StyledFlexBox sx={{ mt: 2 }} className="style-box">
        <SearchInput
          sx={{ width: "inherit" }}
          placeholder="Search users..."
          onKeyPress={handleKeyPress}
        />
        <FilterMenu
          setPageObj={setPageObj}
          pageObj={pageObj}
          showSort={false}
          showStatus={false}
          showRangePicker={false}
          showExport={false}
        />
      </StyledFlexBox>

      <Grid>
        <UserTable
          isSort={true}
          pageObj={pageObj}
          columnShape={UserListColumnShape}
          data={usersData}
          hidePagination={false}
          createSortHandler={createSortHandler}
        />
        {!backdropOpen &&
          (!usersData || (usersData.length <= 0 && <NodataFound />))}
      </Grid>

      {backdropOpen && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            Total Record:
          </Typography>

          <Small
            sx={{
              borderRadius: 10,
              padding: "6px 30px 6px 30px",
              color: "white",
              backgroundColor: "#44564a",
            }}
          >
            {totalRecord || 0}
          </Small>
        </Grid>

        {usersData?.length > 0 && (
          <Stack marginY={1}>
            <StyledPagination
              count={
                (totalRecord && Math.ceil(totalRecord / pageObj.limit)) || 0
              }
              shape="rounded"
              page={pageObj?.pageCount || 0}
              onChange={handleChange}
              onRowsPerPageChange={handleRowsChange}
            />
          </Stack>
        )}
      </Grid>

      <CustomizationDialog
        open={openModal}
        handleClose={handleCloseModal}
        children={
          <UserForm
            id={userId}
            closeModal={handleCloseModal}
            editData={editData}
            pageObj={pageObj}
            setPageObj={setPageObj}
          />
        }
      />
      <CustomizationDialog
        open={openHistoryModal}
        handleClose={handleCloseHistoryModal}
        children={
          <HistoryList
            closeModal={handleCloseHistoryModal}
            editData={editData}
            pageObj={pageObj}
            createSortHandler={createSortHandler}
          />
        }
      />
      <CustomizationDialog
        open={openActivityModal}
        handleClose={handleCloseActivityModal}
        children={
          <UserActivity
            closeModal={handleCloseActivityModal}
            data={activityData}
            pageObj={pageObj}
            createSortHandler={createSortHandler}
          />
        }
      />
    </Box>
  );
};

export default UserList;
