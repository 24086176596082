import React, { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Card } from "@mui/material";
import FlexBox from "../../components/FlexBox";
import LightTextField from "../../components/LightTextField";
import { H1, Small } from "../../components/Typography";
import { TextFieldWrapper } from "../../components/authentication/StyledComponents";
import { useFormik } from "formik";
import useAuth from "../../hooks/useAuth";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { MESSAGE, PAGE_CONSTANT } from "../../utils/constant.jsx";
import logo from "../../Image/logo/Ingredients_Checker.png";
import "../../CommonCss/Button.css";

const Register = () => {
  const { register, isRegister } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = PAGE_CONSTANT.INGREDIENTS_CHECKER;
  }, []);

  useEffect(() => {
    if (isRegister) {
      navigate("/user-verification");
    }
  }, [isRegister]);

  const initialValues = {
    username: "",
    email: "",
    password: "",
    mode: "manual",
    submit: null,
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("User Name is required"),
    email: Yup.string()
      .email(MESSAGE.EMAIL_VALID)
      .max(255)
      .required(MESSAGE.EMAIL_REQUIRED),
    password: Yup.string()
      .min(6, MESSAGE.PASSWORD_MINIMUM)
      .required(MESSAGE.PASSWORD_REQUIRED),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,

      onSubmit: async (values) => {
        try {
          setLoading(true);

          const obj = {
            username: values?.username || "",
            email: values?.email || "",
            password: values.password || "",
            mode: "manual",
            role: "admin",
          };

          const registerUser = await register(obj);

          if (registerUser && registerUser.status === 200) {
            navigate("/user-verification");
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      },
    });

  return (
    <React.Fragment>
      <FlexBox
        sx={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: { sm: "100%" },
        }}
      >
        <Card sx={{ padding: 4, maxWidth: 600, boxShadow: 1 }}>
          <FlexBox
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            mb={5}
          >
            <Box width={100} mb={1}>
              <img src={logo} width="100%" alt="Ingredients_Checker Logo" />
            </Box>
            <H1 fontSize={24} fontWeight={700}>
              Started with Ingredients Checker
            </H1>
          </FlexBox>

          <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
            <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
              <TextFieldWrapper sx={{ mt: 2, width: "100%" }}>
                <LightTextField
                  fullWidth
                  autoComplete="off"
                  name="username"
                  type="text"
                  label="User Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username || ""}
                  error={Boolean(touched.username && errors.username)}
                  helperText={touched.username && errors.username}
                />
              </TextFieldWrapper>

              <TextFieldWrapper sx={{ mt: 2, width: "100%" }}>
                <LightTextField
                  fullWidth
                  autoComplete="off"
                  name="email"
                  type="email"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email || ""}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </TextFieldWrapper>

              <TextFieldWrapper sx={{ mt: 2, width: "100%" }}>
                <LightTextField
                  fullWidth
                  name="password"
                  type="password"
                  label="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password || ""}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </TextFieldWrapper>

              <Box sx={{ mt: 4 }}>
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  variant="contained"
                  fullWidth
                  className="button"
                >
                  Sign Up
                </LoadingButton>
              </Box>
            </form>

            <Small margin="auto" mt={3} color="text.disabled">
              Do you already have an account?
              <Link to="/login">
                <Small color="primary.main">Log in</Small>
              </Link>
            </Small>
          </FlexBox>
        </Card>
      </FlexBox>
    </React.Fragment>
  );
};

export default Register;
