import { Box, Card, Grid, Typography, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Icons from "../../../icons/sidebar";
import React, { useContext } from "react";
import AnimatedNumbers from "react-animated-numbers";
import { BackdropContext } from "../../../contexts/BackdropContext";
import { formatCount, formatCountString } from "../../../utils/common.service";
import "./TransactionCard.css";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "287px",
    borderRadius: theme.spacing(2),
    backgroundColor: "#F5F7FA",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    justifyContent: "space-between",
    marginRight: "14px",
  },
  icon: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    borderRadius: "100%",
    width: 62,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  icon1: {
    color: theme.palette.primary.purple,
    backgroundColor: alpha(theme.palette.primary.purple, 0.2),
    borderRadius: "100%",
    width: 62,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  icon2: {
    color: theme.palette.primary.red,
    backgroundColor: alpha(theme.palette.primary.red, 0.2),
    borderRadius: "100%",
    width: 62,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  icon3: {
    color: theme.palette.primary.yellow,
    backgroundColor: alpha(theme.palette.primary.yellow, 0.2),
    borderRadius: "100%",
    width: 62,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  title: {
    height: "60px",
    fontSize: "37px",
    fontWeight: "600",
    marginBottom: "19px",
    marginBlockStart: "14px",
    paddingInlineStart: "17px",
  },

  box: {
    display: "flex",
    justifyContent: "space-between",

    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",

    padding: theme.spacing(2),
    backgroundColor: "#FFFFFF",

    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },

  count: {
    backgroundColor: "	#f5f5f5	",
    borderRadius: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    width: "70px",
    fontWeight: 600,
    color: "black",
  },

  label: {
    color: "#6C757D",
    maxWidth: "60px",
    fontSize: 12,
  },
}));

const cardList = [
  {
    Icon: Icons.ManageAccountsOutlined,
  },
  {
    Icon: Icons.PeopleAltOutlined,
  },
  {
    Icon: Icons.UserGridIcon,
  },
];

const DashboardCard = (props) => {
  const { userData, postData, ticketData } = props;

  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item md={12} xs={12}>
          <Card
            sx={{
              display: "flex",
              border: "none",
              borderRadius: "10px",
              backgroundColor: "#f3f4f9",
            }}
          >
            <Grid
              container
              rowSpacing={{ xs: 3, sm: 3, md: 3, lg: 1 }}
              columnSpacing={{ xs: 1, sm: 3, md: 1, lg: 1 }}
            >
              {/* <Grid
                item
                xs={12}
                lg={3}
                md={6}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Card className={classes.card}>
                  <Box>
                    <Box className={classes.header}>
                      <Typography
                        variant="h6"
                        className={classes.title}
                        sx={{
                          color: "#2499EF",
                          textShadow: "2px 4px 3px lightblue",
                          display: "flex",
                        }}
                      >
                        <AnimatedNumbers
                          animateToNumber={formatCount(
                            userData.customers.totalCustomers
                          )}
                        />
                        {formatCountString(userData.customers.totalCustomers)}
                      </Typography>

                      <span className={classes.icon}>
                        {React.createElement(cardList[0].Icon)}
                      </span>
                    </Box>
                    <Box
                      sx={{
                        marginBlockStart: "-45px",
                        marginInlineStart: "5px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ padding: "10px", fontSize: "20px" }}
                      >
                        Customers
                      </Typography>
                    </Box>
                    <Box className={classes.box}>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          {formatCount(userData.customers.activeCustomers)}{" "}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: 2 }}
                        >
                          Active
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          {formatCount(userData.customers.deactiveCustomers)}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: 1 }}
                        >
                          Deactive
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          {formatCount(userData.customers.suspendCustomers)}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: 1 }}
                        >
                          Suspend
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Grid> */}

              {/* <Grid
                item
                xs={12}
                lg={3}
                md={6}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Card className={classes.card}>
                  <Box>
                    <Box className={classes.header}>
                      <Typography
                        variant="h6"
                        className={classes.title}
                        sx={{
                          color: "#A798FF",
                          textShadow: "2px 4px 3px #b4b2bf",
                          display: "flex",
                        }}
                      >
                        <AnimatedNumbers
                          animateToNumber={formatCount(
                            userData.staff.totalStaff
                          )}
                        />
                        {formatCountString(userData.staff.totalStaff)}
                      </Typography>
                      <span className={classes.icon1}>
                        {React.createElement(cardList[1].Icon)}
                      </span>
                    </Box>
                    <Box
                      sx={{
                        marginBlockStart: "-45px",
                        marginInlineStart: "5px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ padding: "10px", fontSize: "20px" }}
                      >
                        Staffs
                      </Typography>
                    </Box>
                    <Box className={classes.box}>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          {formatCount(userData.staff.activeStaff)}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: 2 }}
                        >
                          Active
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          {formatCount(userData.staff.deactiveStaff)}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: 1 }}
                        >
                          Deactive
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6" className={classes.count}>
                          {formatCount(userData.staff.suspendStaff)}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                          sx={{ marginLeft: 1 }}
                        >
                          Suspend
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Grid> */}

              {/* <Grid
                item
                xs={12}
                lg={3}
                md={6}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Card className={classes.card}>
                  {ticketData.map((ticketData, i) => (
                    <Box key={i}>
                      <Box className={classes.header}>
                        <Typography
                          variant="h6"
                          className={classes.title}
                          sx={{
                            color: "#FF6B93",
                            textShadow: "2px 4px 3px #e1b9c4",
                            display: "flex",
                          }}
                        >
                          <AnimatedNumbers
                            animateToNumber={formatCount(ticketData.allTickets)}
                          />
                          {formatCountString(ticketData.allTickets)}
                        </Typography>

                        <span className={classes.icon2}>
                          {React.createElement(cardList[0].Icon)}
                        </span>
                      </Box>
                      <Box
                        sx={{
                          marginBlockStart: "-45px",
                          marginInlineStart: "5px",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ padding: "10px", fontSize: "20px" }}
                        >
                          Tickets
                        </Typography>
                      </Box>
                      <Box className={classes.box}>
                        <Box>
                          <Typography variant="h6" className={classes.count}>
                            {formatCount(ticketData.activeTicket)}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            className={classes.label}
                            sx={{ marginLeft: 2 }}
                          >
                            Active
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6" className={classes.count}>
                            {formatCount(ticketData.newTicket)}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            className={classes.label}
                            sx={{ marginLeft: 2.5 }}
                          >
                            New
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6" className={classes.count}>
                            {formatCount(ticketData.closedTicket)}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            className={classes.label}
                            sx={{ marginLeft: 2 }}
                          >
                            Closed
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Card>
              </Grid> */}

              {/* <Grid
                item
                xs={12}
                lg={3}
                md={6}
                sm={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Card className={classes.card}>
                  {postData.map((postData, i) => (
                    <Box key={i}>
                      <Box className={classes.header}>
                        <Typography
                          variant="h6"
                          className={classes.title}
                          sx={{
                            color: "#FF9777",
                            textShadow: "2px 4px 3px #f5e0da",
                            display: "flex",
                          }}
                        >
                          <AnimatedNumbers
                            animateToNumber={formatCount(postData.allPosts)}
                          />
                          {formatCountString(postData.allPosts)}
                        </Typography>
                        <span className={classes.icon3}>
                          {React.createElement(cardList[2].Icon)}
                        </span>
                      </Box>
                      <Box
                        sx={{
                          marginBlockStart: "-45px",
                          marginInlineStart: "5px",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ padding: "10px", fontSize: "20px" }}
                        >
                          Posts
                        </Typography>
                      </Box>
                      <Box className={classes.box} sx={{ gap: "5px" }}>
                        <Box>
                          <Typography variant="h6" className={classes.count}>
                            {formatCount(postData?.unpublishedPost)}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            className={classes.label}
                            sx={{ marginLeft: -0.5 }}
                          >
                            Unpublished
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6" className={classes.count}>
                            {formatCount(postData?.travelPartner)}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            className={classes.label}
                            sx={{ marginLeft: 2 }}
                          >
                            Partner
                          </Typography>
                        </Box>

                        <Box>
                          <Typography variant="h6" className={classes.count}>
                            {postData?.carryParcel}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            className={classes.label}
                            sx={{ marginLeft: 2 }}
                          >
                            Parcel
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Card>
              </Grid> */}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardCard;
