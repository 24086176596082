import { API_CONSTANT } from "../utils/constant.jsx";
import httpCommon from "../core/http.common.jsx";

const userService = {
  register(body) {
    return httpCommon.post(API_CONSTANT.SIGN_UP_DATA, body);
  },

  verificationUser(body) {
    return httpCommon.post(API_CONSTANT.USER_VERIFICATION, body);
  },

  login(body) {
    return httpCommon.post(API_CONSTANT.LOGIN, body);
  },

  forgotPassword(body) {
    return httpCommon.post(API_CONSTANT.FORGET_PASSWORD, body);
  },

  passwordVerification(body) {
    return httpCommon.put(API_CONSTANT.PASSWORD_VERIFICATION, body);
  },
  getSupports(body) {
    return httpCommon.post(API_CONSTANT.SUPPORT_LIST, body);
  },

  updateSupportStatus(id, status) {
    const url = `${API_CONSTANT.UPDATE_SUPPORT_STATUS + id}`;
    return httpCommon.post(url, { status });
  },
  createThread(body = {}) {
    return httpCommon.post(API_CONSTANT.CREATE_THREAD, body);
  },

  getThreadByTicket(ticketNo) {
    const url = `${API_CONSTANT.GET_THREAD_BY_TICKET + ticketNo}`;
    return httpCommon.get(url);
  },

  resetPassword(body) {
    return httpCommon.put(API_CONSTANT.RESET_PASSWORD, body);
  },

  getAllUsers(body = {}) {
    return httpCommon.post(API_CONSTANT.GET_ALL_USER, body);
  },
  getAllHistory(body = {}) {
    return httpCommon.post(API_CONSTANT.GET_ALL_HISTORY, body);
  },

  getUserById(_id) {
    const url = `${API_CONSTANT.GET_USER_BY_ID + _id}`;
    return httpCommon.post(url);
  },

  updateUserById(body = {}) {
    const url = `${API_CONSTANT.UPDATE_USER_BY_ID}`;
    return httpCommon.put(url, body);
  },

  deleteUser(id, Body) {
    const url = `${API_CONSTANT.DELETE_USER_BY_ID + id}`;
    return httpCommon.delete(url, Body, { id });
  },

  getAllIngredient(body = {}) {
    return httpCommon.post(API_CONSTANT.FIND_ALL_INGREDIENT, body);
  },

  getIngredientById(_id) {
    const url = `${API_CONSTANT.GET_INGREDIENT_BY_ID + _id}`;
    return httpCommon.get(url);
  },

  updateIngredientById(_id, body = {}) {
    const url = `${API_CONSTANT.UPDATE_INGREDIENT_BY_ID + _id}`;
    return httpCommon.put(url, body);
  },

  deleteIngredientById(id, Body) {
    const url = `${API_CONSTANT.DELETE_INGREDIENT_BY_ID + id}`;
    return httpCommon.delete(url, Body, { id });
  },

  fetchFeedback(body) {
    return httpCommon.post(API_CONSTANT.GET_ALL_FEEDBACK, body);
  },
  updateFeedback(body) {
    const url = `${API_CONSTANT.FEEDBAck_UPDATE}`;
    return httpCommon.put(url, body);
  },
  getUserActivityById(_id) {
    const url = `${API_CONSTANT.GET_USER_ACTIVITY_BY_ID + _id}`;
    return httpCommon.get(url);
  },
  fetchAllUserActivity(body) {
    return httpCommon.post(API_CONSTANT.GET_ALL_USERACITIVITY, body);
  },
};

export default userService;
