import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { BootstrapDialogTitle } from "../../components/Layouts/Modal/CustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import {
  capitalizeFirstLetter,
  sentenceCase,
} from "../../utils/common.service";
import { Box } from "@mui/system";
import "./Ingredient.css";
import moment from "moment";
import { CONSTANT } from "../../utils/constant";
import { H3 } from "../../components/Typography";

const IngredientDetailsForm = (props) => {
  const { closeModal, data } = props;

  const feedbakeCard = (title, value) => {
    return (
      <Grid sx={{ marginTop: "5px", pb: "5px" }}>
        <Grid>
          <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
        </Grid>
        <Grid borderBottom={"0.5px solid #e9e9e9"}>
          <Typography
            align="left"
            sx={{ py: "5px", color: "#2e2e2e" }}
            className="letter-spacing"
          >
            {value || "-"}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <BootstrapDialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-20px",
          marginLeft: "-8px",
          marginBottom: "-10px",
        }}
      >
        <h3 className="ingredient-title">Ingredient Details</h3>

        <IconButton aria-label="close">
          <CloseIcon onClick={closeModal} />
        </IconButton>
      </BootstrapDialogTitle>

      <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 172px)" }}>
        <Grid sx={{ height: "450px" }}>
          {feedbakeCard("Title", data?.original?.title || "-")}
          {feedbakeCard("Description", data?.original?.description || "-")}

          <Grid sx={{ marginTop: "5px", pb: "5px" }}>
            <Grid>
              <Typography sx={{ fontWeight: 600 }}>
                Description Overview
              </Typography>
            </Grid>
            <Grid borderBottom={"0.5px solid #e9e9e9"}>
              <p
                className="letter-spacing"
                dangerouslySetInnerHTML={{
                  __html: data?.original?.detailedOverview,
                }}
              ></p>
            </Grid>
          </Grid>

          {feedbakeCard(
            "Health Status",
            capitalizeFirstLetter(data?.original?.health_status) || "-"
          )}
          {feedbakeCard("Search Count", data?.original?.searchCount || "-")}
          {feedbakeCard("Count Down", data?.original?.countdown || "-")}

          {feedbakeCard(
            "Created Date",
            moment(data?.original?.createdAt).format(CONSTANT.DATE_FORMATE) ||
              "-"
          )}

          <Grid sx={{ marginTop: "5px", pb: "5px" }}>
            <Grid>
              <Typography sx={{ fontWeight: 600 }}>Updated Date</Typography>
            </Grid>
            <Grid>
              <Typography
                align="left"
                sx={{ py: "5px", color: "#2e2e2e" }}
                className="letter-spacing"
              >
                {moment(data?.original?.updatedAt).format(
                  CONSTANT.DATE_FORMATE
                ) || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default IngredientDetailsForm;
