import React from "react";
import axios from "axios";
import { BASE_URL } from "../utils/constant";
import { userTokenMethod } from "../utils/common.service";

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(function (config) {
  let token;
  if (userTokenMethod("get")) {
    token = userTokenMethod("get");
  }

  return {
    ...config,
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      userTokenMethod("delete");
      window.location.reload();
    }
    return error;
  }
);

const responseBody = (response) => {
  return {
    ...(response?.response?.data || response.data),
    status: response?.response?.status || response?.status || 404,
  };
};

const requests = {
  get: (url, body) => instance.get(url, body).then(responseBody),

  post: (url, body, headers) =>
    instance.post(url, body, headers).then(responseBody),

  put: (url, body) => instance.put(url, body).then(responseBody),

  delete: (url, body) => instance.delete(url, body).then(responseBody),
};

export default requests;
