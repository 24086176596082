import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import LightTextField from "../LightTextField";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import "../../CommonCss/Button.css";
import userService from "../../service/user.service";
import { notifyError, notifySuccess } from "../../utils/common.service";
import { BootstrapDialogTitle } from "../Layouts/Modal/CustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import "../../CommonCss/Button.css";
import InputEmoji from "react-input-emoji";
import { H3 } from "../Typography";

const FeedbackForm = (props) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  const { feedbackData, closeModal, setPageObj, pageObj, id } = props;

  const handleEmojiInputChange = (newText) => {
    handleChange({
      target: {
        name: "reply",
        value: newText,
      },
    });
  };
  const feedbakeCard = (title, value) => {
    return (
      <Grid sx={{ marginTop: "5px", pb: "5px", mb: "12px", ml: "15px" }}>
        <Grid>
          <Typography sx={{ fontWeight: 600, marginBottom: 0.5 }}>
            {title}
          </Typography>
        </Grid>
        <Grid borderBottom={"0.5px solid #e9e9e9"}>
          <Typography align="left" sx={{ marginBottom: "5px", color: "gray" }}>
            {value || "-"}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const initialValues = {
    _id: id,
    name: feedbackData?.reply || "",
  };

  const { values, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues,

    onSubmit: async () => {
      try {
        setButtonLoading(true);
        userService
          .updateFeedback({
            _id: id,
            reply: values.reply,
          })

          .then((res) => {
            const { status, message } = res;
            if (status === 200) {
              setButtonLoading(false);
              notifySuccess(message);

              closeModal();

              setPageObj({
                ...pageObj,
                refresh: Date.now(),
              });
            } else {
              setButtonLoading(false);
              notifyError(message);
            }
          })
          .catch((err) => {
            setButtonLoading(true);
            notifyError(err ? err?.response?.data?.message : err?.message);
          });
      } catch (err) {
        setButtonLoading(true);
        notifyError(err ? err?.response?.data?.message : err?.message);
      }
    },
  });

  let res = feedbackData.find((obj) => obj._id === id);

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <BootstrapDialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "-20px",
            marginLeft: "-8px",
            marginBottom: "-10px",
          }}
        >
          <h3>{res?.reply ? "Feedback Detail" : "Reply Feedback"}</h3>

          <IconButton>
            <CloseIcon onClick={closeModal} />
          </IconButton>
        </BootstrapDialogTitle>

        <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 172px)" }}>
          {feedbakeCard("FeedBackId", res?._id || "-")}

          {feedbakeCard("User", res?.user_id || "-")}

          {feedbakeCard("Message", res?.feedback || "-")}

          {res?.reply ? (
            feedbakeCard("Replied", res?.reply || "-")
          ) : (
            <Grid
              spacing={2}
              container
              sx={{
                height: "230px",
              }}
            >
              <Grid className="reply-feedback" item xs={12}>
                <Typography
                  sx={{ fontWeight: 600, marginBottom: 1, ml: "15px" }}
                >
                  Reply
                </Typography>
                <InputEmoji
                  name="reply"
                  type="textarea"
                  multiline
                  placeholder="Reply Message"
                  value={values.reply || ""}
                  onChange={handleEmojiInputChange}
                  className="input-field"
                  keepOpened
                  shouldReturn
                />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  sx={{ maxWidth: "200px" }}
                  type="submit"
                  loading={buttonLoading}
                  variant="contained"
                  fullWidth
                  className="button"
                >
                  Reply Feedback
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </form>
  );
};

export default FeedbackForm;
